import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Link,
  Box,
  Grid,
  Stack,
  Button,
  Typography,
  Modal,
  TextField,
} from '@mui/material';
import { getCurrentUser, loginUser } from '../../utils/api';
import { setUserUrlParameter } from '../../utils/helper';
import getIcon from '../../utils/getIcon';
import useMatomo from '../../utils/tracking';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'secondary.light',
  boxShadow: 24,
  p: 4,
  // minHeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Login = (props = { callbackFn }) => {
  const theme = useTheme();

  const { trackEvent } = useMatomo();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setUser();
    setOpen(false);
    setError();
  };

  const handleInputChange = event => {
    setValue(event.target.value);
  };

  const submitHandler = () => {
    setLoading(true);
    loginUser(value)
      .then(res => {
        if (res.user) {
          console.log('User login response: ', res);
          setError(false);
          setUserUrlParameter(res.user);
          getCurrentUser().then(res => {
            console.log('Current user response: ', res);
          });

          setTimeout(() => {
            props.callbackFn(res.user);
            setUser(res.user);
            setLoading(false);
            setTimeout(() => {
              setOpen(false);
            }, 1500);
          }, 500);

          trackEvent(null, 'UserCode', 'StartWithCode');
        } else {
          setTimeout(() => {
            setLoading(false);
            setError('Der Karriere-Code ist ungültig');
          }, 500);
        }
      })
      .catch(e => {
        setTimeout(() => {
          setError(`Ein Fehler ist aufgetreten: ${e.message}`);
          setLoading(false);
        }, 500);
      });
  };

  const onKeyDown = event => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submitHandler();
    }
  };

  let Icon = { ...getIcon('checklabel') };

  // Modify icon props
  if (Icon) {
    Icon.props = {
      ...Icon.props,
      sx: {
        width: 50,
        height: 50,
        position: 'relative',
      },
    };
  }

  return (
    <Box display="flex" justifyContent="center">
      <Link component="button" textAlign={'center'} onClick={handleOpen}>
        Ich habe einen Karriere-Code
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" noValidate autoComplete="off">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              style={{ width: '100%' }}
              initial={user && { opacity: 0, scale: 0.5 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
              }}
              key={user}
            >
              {!user ? (
                <Stack spacing={2}>
                  <TextField
                    error={error}
                    helperText={error}
                    autoFocus={true}
                    label="Karriere-Code"
                    variant="outlined"
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                  />
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    fullWidth
                    onClick={submitHandler}
                    disabled={!value || value.length < 1}
                  >
                    Anmelden
                  </LoadingButton>
                </Stack>
              ) : (
                <Stack spacing={4}>
                  <Box display="flex" justifyContent="center">
                    {Icon}
                  </Box>
                  <Typography textAlign="center">
                    Anmeldung erfolgreich!
                  </Typography>
                </Stack>
              )}
            </motion.div>
          </AnimatePresence>
        </Box>
      </Modal>
    </Box>
  );
};

export default Login;
