import StepExplanation from './StepExplanation';
import StepperContext from '../StepperContext';

const defaultExport = {
  title: 'Components/Stepper/Steps/Explanation',
  component: StepExplanation,
  // argTypes: {
  // 	...ImageStories.default.argTypes,
  // },
};

const Template = args => (
  <StepperContext.Provider
    value={{
      user: 'b78379a7-4302-4100-8c3e-d198f40b0782',
    }}
  >
    <StepExplanation {...args} />
  </StepperContext.Provider>
);

export const Default = Template.bind({});

Default.args = {
  // title: 'Karrierenavigator',
  text: '<p>Der Karrierenavigator ist ein <strong>Selbsttest</strong>, der dir auf Basis deiner Interessen und Fähigkeiten Ausbildungsberufe und/oder Studienfächer vorschlägt.</p><p><strong>Im ersten Teil</strong> beantwortest du Fragen zu deinen <strong>Interessen</strong>. In einem Zwischenergebnis wird dir angezeigt wie gut deine Interessen zu verschiedenen Berufsbereichen passen.</p><p><strong>Im zweiten Teil</strong> beantwortest du Fragen zu deinen <strong>Fähigkeiten</strong>.</p><p>Wenn du alle Fragen beantwortet hast, wird dir angezeigt, wie deine <strong>Interessen und Fähigkeiten</strong> zu verschiedenen Berufsbereichen passen. Außerdem siehst du, welche <strong>MINT-Ausbildungsberufe und/oder - Studienfächer</strong> am besten zu deinem Profil passen.</p>',
  button: {
    label: 'Verstanden',
  },
};

export default defaultExport;
