import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import { Container, Box, Stack, Button, Typography, Grid, Link } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { createUser } from '../utils/api';

const StepInit = ({ questions }) => {
  const { setStepIndex, setUser } = useContext(StepperContext);
  const [state, setState] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerIndex, setAnswerIndex] = useState(null);
  const [isLastQuestion, setIsLastQuestion] = useState(
    questionIndex >= questions.length,
  );

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setIsLastQuestion(questionIndex >= questions.length - 1);
  }, [questionIndex]);

  const answerButtonHandler = (index, name) => {
    const newAnswers = [...answers, name];

    setAnswerIndex(Number(index));
    setState(1);
    setAnswers(newAnswers);

    // Last question answered
    if (questionIndex >= questions.length - 1) {
      createUser(newAnswers.join('/'))
        .then(res => {
          console.log('res: ', res);
          setUser(res.user);
        })
        .catch(e => {
          console.log(e.message);
        });
    }

    setTimeout(() => {
      setTimeout(() => {
        if (!isLastQuestion) {
          setQuestionIndex(questionIndex + 1);
          setState(0);
          setAnswerIndex(null);
        } else {
          setStepIndex && setStepIndex('userCode');
        }
      }, 700);
    }, 500);
  };

  const goBackInQuestionIndex = () => {
    if ( questionIndex > 0 ) {
      setAnswerIndex(answerIndex - 1);

      // Remove last item but without using "pop" function (cause we need the new array as return value)
      setAnswers(answers.slice(0, -1));

      setQuestionIndex(questionIndex - 1);
      setState(0);
      setAnswerIndex(null);
    }
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Stack sx={{ height: '100%' }}>
        {/* Question title */}
        <Box>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{
                opacity: 0,
                x: 10,
                scale: 1,
              }}
              animate={() => {
                if (Number(state) === 0) {
                  return {
                    opacity: [0, 1],
                    x: [20, -5, 0],
                    scale: 1,
                    transition: { duration: 0.3, delay: 0 },
                  };
                } else {
                  return {
                    opacity: 0,
                    x: [0, 5, -20],
                    scale: 1,
                    transition: { duration: 0.3, delay: 0.75 },
                  };
                }
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                // component="h1"
                textAlign="center"
                sx={{ mb: 6 }}
              >
                {questions[questionIndex].title}
              </Typography>
            </motion.div>
          </AnimatePresence>
        </Box>

        {/* Answers */}
        <Stack spacing={2}>
          {questions[questionIndex].options.map((option, index) => {
            return (
              <Box key={index}>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    key={questionIndex}
                    initial={{
                      opacity: 0,
                      x: 20,
                      scale: 1,
                    }}
                    animate={() => {
                      if (Number(state) === 0) {
                        return {
                          opacity: [0, 1],
                          x: [20, -5, 0],
                          scale: 1,
                          transition: {
                            duration: 0.3,
                            delay: 0.3 + index * 0.1,
                          },
                        };
                      } else if (state === 1) {
                        return {
                          x: [0, 5, -20],
                          opacity: [1, 1, 0],
                          transition: {
                            duration: 0.3,
                            delay: 0.3 + index * 0.1,
                          },
                        };
                      }
                    }}
                  >
                    {/* <Box> */}
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{
                          opacity: 1,
                          scale: 1,
                        }}
                        animate={() => {
                          if (state === 1) {
                            if (Number(answerIndex) === Number(index)) {
                              return {
                                opacity: 1,
                                scale: 1.025,
                              };
                            } else {
                              return {
                                opacity: 0.5,
                                scale: 0.95,
                              };
                            }
                          }
                        }}
                        transition={{ delay: index * 0.05 }}
                      >
                        <Stack sx={{ px: 1 }}>
                          <Button
                            fullWidth
                            variant={
                              'outlined'
                              // state === 1 &&
                              // Number(answerIndex) === Number(index)
                              //   ? 'contained'
                              //   : 'outlined'
                            }
                            onClick={() =>
                              answerButtonHandler(index, option.name)
                            }
                          >
                            {option.label}
                          </Button>
                        </Stack>
                      </motion.div>
                    </AnimatePresence>
                    {/* </Box> */}
                  </motion.div>
                </AnimatePresence>
              </Box>
            );
          })}

          {/* Back button */}
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={questionIndex}
              initial={{
                opacity: 0,
                x: 20,
                scale: 1,
              }}
              animate={() => {
                if ( questionIndex > 0 ) {
                  if (Number(state) === 0) {
                    return {
                      opacity: [0, 1],
                      x: [20, -5, 0],
                      scale: 1,
                      transition: {
                        duration: 0.3,
                        delay: 0.3 + questions[questionIndex].options.length * 0.1,
                      },
                    };
                  } else if (state === 1) {
                    return {
                      x: [0, 5, -20],
                      opacity: [1, 1, 0],
                      transition: {
                        duration: 0.3,
                        delay: 0.3 + questions[questionIndex].options.length * 0.1,
                      },
                    };
                  }
                }
              }}
            >
              <Stack sx={{ px: 1, pt: 3 }}>
                <Link component="button" textAlign={'center'} onClick={() =>
                  goBackInQuestionIndex()
                }>
                  Zurück
                </Link>
              </Stack>
            </motion.div>
          </AnimatePresence>

        </Stack>
      </Stack>
    </Container>
  );
};

export default StepInit;
