import React, { useContext } from 'react';
import StepperContext from '../StepperContext';
import { Grid, Container, Box, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
// import { useTheme } from '@mui/material/styles';
import IconAnimation from '../Partials/IconAnimation/IconAnimation';
import AnimatedButton from '../Partials/AnimatedButton/AnimatedButton';

const StepQuestionsComplete = (
  props = { redirectIndex, headline, subline, text, buttonLabel },
) => {
  // const theme = useTheme();
  // const colors = theme.palette;

  const { currentIndex, setStepIndex, questionGroup } =
    useContext(StepperContext);

  const currentQuestion = questionGroup;

  const show = props.show || true;
  const icon = props.icon || currentQuestion?.icon || 'checklabel';
  const headline = props.headline || currentQuestion?.label;
  const subline = props.subline;
  const text = props.text;

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Stack sx={{ p: 2, height: '100%' }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {/* Headline */}
          {headline && (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0, x: 20, scale: 1 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  transition: { delay: 0 },
                }}
                exit={{ opacity: 0, x: -20, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.4 }}
                key={currentIndex}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ mb: 4 }}
                >
                  {headline}
                </Typography>
              </motion.div>
            </AnimatePresence>
          )}
          {icon && (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <IconAnimation show={show} width={150} height={150} icon={icon} />
            </Box>
          )}

          {/* Subline */}
          <Typography
            variant="h4"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            {subline}
          </Typography>

          {/* Text */}
          <Typography variant="h6" textAlign="center" gutterBottom>
            {text}
          </Typography>
        </Box>
        {/* Continue Button */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.5 }}
            key={show}
          >
            <Stack sx={{ px: 1, pt: 2 }}>
              <AnimatedButton
                activeAnimation
                variant="contained"
                children={props.buttonLabel || 'Weiter'}
                show={show}
                onClick={() => {
                  props.redirectIndex && setStepIndex(props.redirectIndex);
                }}
              />
            </Stack>
          </motion.div>
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

export default StepQuestionsComplete;
