import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { Box, Grid, Stack, Button, Typography } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

/**
 *
 * Progress Circle
 * This component is based on the following approach:
 * https://codepen.io/AvocadoVenom/pen/rNpdYyb
 */
const ProgressCircle = (
  props = {
    show,
    active,
    progress,
    showLabelOnZero,
    animationDelay,
  },
) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (props.show) {
      if (progress === null && props.animationDelay) {
        setTimeout(() => {
          setProgress(props.progress);
        }, props.animationDelay);
      } else {
        setProgress(props.progress);
      }
    } else {
      setProgress(null);
    }
  }, [props.show, props.progress]);

  return (
    // <Grid item xs={2} display="flex" justifyContent="flex-end">
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ scale: 1, opacity: 0 }}
        animate={
          props.show
            ? {
                opacity: 1,
                scale: [1.3, 1],
                transition: { delay: 0, duration: 0.2 },
              }
            : {
                opacity: 0,
                scale: [1, 1.3],
                transition: { delay: 0.4, duration: 0.2 },
              }
        }
        exit={{
          opacity: 0,
          scale: [1, 1.05, 0.5],
          transition: { delay: 0, duration: 0.3 },
        }}
        // key={props.show}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              width: 50,
              height: 50,
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              alignContent: 'stretch',
              alignItems: 'center',
            }}
          >
            <svg
              className="circle-container"
              viewBox="2 -2 28 36"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: 50,
                height: 50,
                transform: 'rotate(-90deg)',
                fill: 'none',
                stroke: 'white',
                // strokeDasharray: '20',
                strokeLinecap: 'round',
              }}
            >
              {/* Gradient pattern*/}
              <linearGradient
                id="gradient"
                style={{
                  transform: `rotate(${props.progress}deg)`,
                }}
              >
                <stop
                  className="stop1"
                  offset="0%"
                  style={{ stopColor: theme.palette.secondary.main }}
                />
                <stop
                  className="stop2"
                  offset="100%"
                  style={{ stopColor: '#0096D2' }}
                />
              </linearGradient>

              {/* Gradient pattern for filled circle */}
              <radialGradient id="radial-gradient">
                <stop
                  className="stop1"
                  offset="0%"
                  style={{ stopColor: theme.palette.secondary.main }}
                />
                <stop
                  className="stop2"
                  offset="100%"
                  style={{ stopColor: '#31CAB4' }}
                />
              </radialGradient>

              {/* Background circle */}
              <circle
                r="16"
                cx="16"
                cy="16"
                shapeRendering="geometricPrecision"
                style={{
                  fill: 'none',
                  stroke: 'rgba(0,0,0,.075)',
                  strokeWidth: 3,
                  // strokeDasharray: '100',
                  strokeLinecap: 'round',
                }}
              ></circle>

              {/* Progress circle */}
              <circle
                r="16"
                cx="16"
                cy="16"
                style={{
                  strokeDashoffset: 0,
                  fill: 'none',
                  strokeLinecap: 'round',
                  stroke: 'url(#gradient)',
                  strokeDasharray: `${props.progress} 100`,
                  strokeLinecap: 'round',
                  strokeWidth: 3,
                  transition: 'all .2s ease-in-out',
                }}
                shapeRendering="geometricPrecision"
              ></circle>

              {/* Filled circle */}
              <circle
                r="18"
                cx="16"
                cy="16"
                style={{
                  opacity: props.progress >= 95 ? 1 : 0,
                  // strokeDashoffset: 0,
                  // strokeLinecap: 'round',
                  fill: 'url(#radial-gradient)',
                  // fill: theme.palette.secondary.main,
                  // strokeDasharray: `${props.progress} 100`,
                  // strokeLinecap: 'round',
                  strokeWidth: 0,
                  transition: 'all .2s ease',
                }}
                shapeRendering="geometricPrecision"
              ></circle>
            </svg>
          </Box>

          {/* Text content */}
          <Box
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0 }}
                animate={
                  props.show && (progress != null || props.showLabelOnZero)
                    ? {
                        opacity: 1,
                        scale: [1.2, 1],
                        transition: {
                          // delay: props.animationDelay / 2000,
                          duration: 0.5,
                        },
                      }
                    : {
                        opacity: 0,
                        scale: 0,
                      }
                }
              >
                <Typography
                  lineHeight={1}
                  fontWeight={700}
                  variant="caption"
                  component="div"
                  color="text.secondary"
                  sx={{ letterSpacing: '-.5px', wordSpacing: 2 }}
                >
                  {`${Math.round(props.progress)}`}
                  <small>%</small>
                </Typography>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </motion.div>
    </AnimatePresence>
    // </Grid>
  );
};

export default ProgressCircle;
