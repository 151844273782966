import React, { useState, useEffect, useContext, useCallback } from 'react';
import StepperContext from '../StepperContext';
import { styled } from '@mui/material/styles';
import {
  Container,
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconAnimation from '../Partials/IconAnimation/IconAnimation';
import AnimatedButton from '../Partials/AnimatedButton/AnimatedButton';
import Login from '../Partials/Login/Login';
import { Default } from './StepUserCode.stories';

const CssTextField = styled(TextField)({
  flexGrow: 1,

  '& .MuiInputBase-root': {
    color: 'white',
    height: '100%',
  },
});

const StepUserCode = props => {
  const { title, subline, buttonLabel, redirectIndex } = props;
  const [show, setShow] = useState(false);
  const [snackbarClipboardOpen, setSnackbarClipboardOpen] = useState(false);

  useEffect(() => {
    setShow(props.show !== undefined ? props.show : true);
  }, [props.show]);

  const { setStepIndex, user } = useContext(StepperContext);

  const buttonClickHandler = () => {
    handleClose();
    setStepIndex && setStepIndex('explanation');
  };

  const handlyCopyToClipboard = string => {
    navigator.clipboard.writeText(string);
    setSnackbarClipboardOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarClipboardOpen(false);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', position: 'relative' }}>
      <Stack sx={{ height: '100%', py: 4 }}>
        {/* Headline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                // scale: 1,
                scale: 1,
              }
            }
            exit={{
              opacity: 0,
              // scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.3, delay: 0 }}
            key={show}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              component="h1"
              textAlign="center"
              gutterBottom
            >
              {title || Default.args.title}
            </Typography>
          </motion.div>
        </AnimatePresence>

        {/* Icon */}
        {(props.icon || Default.args.icon) && (
          <Box flexGrow={1} sx={{ textAlign: 'center', my: 2 }}>
            <IconAnimation show={show} icon={props.icon || Default.args.icon} />
          </Box>
        )}

        {/* Subline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.4, delay: 0.05 }}
            key={show}
          >
            <Typography
              variant="h6"
              component="h2"
              fontWeight={200}
              textAlign="center"
              sx={{ mb: 4 }}
            >
              {subline || Default.args.subline}
            </Typography>
          </motion.div>
        </AnimatePresence>

        {/* Textfield with user code */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.025, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.025, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.15 }}
            key={show}
          >
            <Stack spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'stretch',
                }}
              >
                <CssTextField
                  value={user || ''}
                  onClick={() => handlyCopyToClipboard(user)}
                  sx={{ backgroundColor: 'primary.main' }}
                />
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handlyCopyToClipboard(user);
                  }}
                >
                  <ContentCopyIcon />
                </Button>
              </Box>
              {/* {(window.sidebar?.addPanel || window.external?.AddFavorite) && (
                <AnimatedButton
                  activeAnimation
                  variant="contained"
                  children={buttonLabel || 'Los Gehts'}
                  show={show}
                  onClick={bookmarkHandler}
                />
              )} */}
            </Stack>
          </motion.div>
        </AnimatePresence>

        {setStepIndex && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, scale: 0.75 }}
              animate={
                show && {
                  opacity: 1,
                  scale: [0, 1.025, 0.95, 1],
                }
              }
              exit={{
                opacity: 0,
                scale: [1, 1.025, 0.8],
              }}
              transition={{ duration: 0.4, delay: 0.25 }}
              key={show}
            >
              <Box sx={{ pt: 2 }}>
                <AnimatedButton
                  activeAnimation
                  variant="contained"
                  children="Weiter"
                  show={show}
                  onClick={buttonClickHandler}
                />
              </Box>
            </motion.div>
          </AnimatePresence>
        )}
      </Stack>

      <Snackbar
        open={snackbarClipboardOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {Default.args.copiedCodeConfirmation}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StepUserCode;
