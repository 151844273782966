import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  Box,
  Grid,
  Stack,
  Button,
  Typography,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

export const QuestionGroupButton = (
  props = {
    label,
    countQuestions,
    countAnswers,
    icon,
    active: null,
    animate: true,
    delayActivateAnimation: null,
    onClick: null,
  },
) => {
  const theme = useTheme();

  let icon = props.icon ? { ...props.icon } : null;
  if (icon) {
    icon.props = {
      ...icon.props,
      sx: {
        width: 60,
        height: 60,
      },
    };
  }

  return (
    <Box className="QuestiongroupButton">
      {/* active animation */}
      <motion.div
        initial={{
          scale: 1,
          background: theme.palette.grey[200],
        }}
        animate={() => {
          if (props.active) {
            return {
              scale: [1, 0.98, 1.025, 1, 1.01, 1],
              background: theme.palette.secondary.main,
              transition: {
                duration: props.animate ? 0.4 : 0.01,
                delay: props.delayActivateAnimation || 0,
              },
            };
          } else {
            return {
              scale: [1, 0.98, 1],
              background: theme.palette.grey[200],
              transition: {
                duration: 0.2,
              },
            };
          }
        }}
      >
        <Card
          elevation={0}
          sx={{ background: 'rgba(0,0,0,0)', position: 'relative' }}
          className="QuestiongroupButton__Card"
        >
          <CardActionArea component="div" onClick={props.onClick}>
            <CardContent sx={{ px: { xs: 1, sm: 1.5, md: 2 }, py: 0 }}>
              <Grid container spacing={0} flexWrap="nowrap">
                {/* Card Icon */}
                {icon && (
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      pr: { xs: 1, sm: 2 },
                      '& svg': { width: { xs: 35, sm: 45, md: 55 } },
                    }}
                  >
                    {icon}
                  </Grid>
                )}
                {/* Card left content */}
                <Grid
                  item
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Box
                    sx={{
                      py: { xs: 1.5, sm: 2, md: 3 },
                      px: 0,
                      width: '100%',
                    }}
                  >
                    {/* Question title */}
                    <Typography fontWeight="bold" sx={{ mb: 0 }}>
                      {props.label}
                    </Typography>

                    <Grid container justifyContent={'space-between'}>
                      <Grid item>
                        {/* Results counter */}
                        <Typography variant="caption">
                          {props.countAnswers} / {props.countQuestions}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {/* Check icon */}
              {/* <Box sx={{}}> */}
              <motion.div
                style={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  display: 'flex',
                }}
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                animate={() => {
                  if (props.active) {
                    return {
                      scale: [0.5, 1.2, 0.9, 1],
                      opacity: [0, 1, 1, 1],
                      transition: props.animate
                        ? {
                            duration: 0.3,
                            delay: (props.delayActivateAnimation || 0) + 0.25,
                          }
                        : {
                            duration: 0.01,
                          },
                    };
                  } else {
                    return {
                      scale: 0,
                      opacity: 0,
                      transition: { duration: 0.2 },
                    };
                  }
                }}
              >
                <CheckCircleIcon fontSize="small" />
              </motion.div>
              {/* </Box> */}
            </CardContent>
          </CardActionArea>
        </Card>
      </motion.div>
    </Box>
  );
};

export default QuestionGroupButton;
