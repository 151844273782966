export const findByKey = (object, key, value) => {
  let result = null;

  Object.entries(object).map((obj, index) => {
    if (obj[1][key] === value) {
      result = obj[1];
    }
  });

  return result;
};

export const createSubarray = (largeArray, n) => {
  try {
    if (n <= largeArray.length) {
      return largeArray.slice(0, n);
    } else {
      return largeArray;
    }
  } catch (e) {
    // An error will be thrown if largeArray does not have a length.
    return [];
  }
};

export const getTopData = ({ results }) => {
  const educationResults = findByKey(results, 'type', 'education');

  let topData = {};

  educationResults &&
    Object.entries(educationResults).map((result, index) => {
      if (Array.isArray(result[1])) {
        topData[result[0]] = createSubarray(result[1], 5);
      }
    });

  return topData;
};

export const resultDescriptions = {
  Handel:
    'Im Handel wird das Wissen über technische Produkte genutzt, um Kunden zu beraten. Hier sind außerdem sicheres Auftreten und Überzeugungskraft wichtig. In Zukunft werden viele Waren online verkauft. Wer sich hier auskennt, hat große Vorteile.',
  Handwerk:
    'Im Handwerk wird mit der Hand, Werkzeugen und Maschinen gearbeitet: Es wird gebaut, geschraubt, gezeichnet, aber auch kontrolliert und überwacht. Gefragt sind handwerkliches Geschick, technisches Verständnis sowie räumliches Denken. Besondere Freude am Bearbeiten von Holz, Metall, Stein oder Glas sind ebenso wichtig.',
  Informatik:
    'In der Informatik dreht sich alles um Daten, Zahlen und mathematische Formeln, z. B. beim Programmieren oder beim Entwickeln von digitalen Lösungen. Zudem sind technisches Verständnis, eine schnelle Auffassungsgabe sowie Geduld und Belastbarkeit wichtig.',
  'Kreative Technik':
    'In diesem Bereich ist die Technik nur das Werkzeug, um neue Ideen umzusetzen. Wichtig ist dabei ein gutes technisches Verständnis, selbstständige Suche nach technischen Lösungen und ihre genaue Umsetzung. Zielstrebigkeit und gute Kommunikation mit Kunden sind hier von Vorteil.',
  Landwirtschaft:
    'In der Landwirtschaft dreht sich alles um pflanzliche und tierische Erzeugnisse, sowie Technologien, die in dem Bereich eingesetzt oder entwickelt werden. Kenntnisse in Biologie, Chemie und Naturkunde spielen eine große Rolle, genauso Arbeiten unter freiem Himmel zu unterschiedlichen Jahreszeiten. Fragen zu Ökologie und Klimaschutz, aber auch Digitalisierung werden in Zukunft besonders wichtig sein.',
  Mathematik:
    'In der Mathematik dreht sich alles um Zahlen, Formeln und geometrische Figuren. Logisches Denken und Durchhaltevermögen sind hier gefragt. Mathematik ist auch die Grundlage in jedem technischen und wirtschaftlichen Bereich.',
  Medientechnik:
    'In der Medientechnik geht es um technische Lösungen in der Welt der Medien. Hier verbinden sich Funktion, Inhalt und Schönheit miteinander. Berufe in der Medientechnik zeichnen sich durch ein hohes Arbeitstempo aus. Daher sind Flexibilität und Selbstständigkeit von großer Bedeutung.',
  'Medizinische Technik':
    'Hier werden Geräte und Maschinen entwickelt und gebaut, die das Leben von Menschen verbessern und verlängern können. Tätigkeiten in diesem Bereich vereinen eine sorgfältige Arbeitsweise, Aufmerksamkeit für die kleinsten Details und gutes räumliches Denken.',
  Naturwissenschaft:
    'In den Naturwissenschaften geht es darum, Dinge in der Natur zu erforschen und praktisch zu nutzen. Das Wissen in der Physik, Chemie oder Biologie entwickelt sich schnell weiter, deshalb sind hier Neugier, Lernbereitschaft und gut organisierte Arbeitsweise besonders wichtig.',
  Technik:
    'Hier dreht sich alles um Maschinen und technische Geräte, die entwickelt, konstruiert, angewendet oder repariert werden. Handwerkliches Geschick, aber auch Kenntnisse in Mathe und Physik sind hier wichtig. Auch Arbeit am Computer spielt eine große Rolle.',
  Unternehmertum:
    'Für eine Unternehmensgründung im technischen Bereich sind Begeisterung für Technologien und eine wirtschaftliche Denkweise wichtig. Selbstständig werden kann man in fast jedem Beruf. Man sollte gerne Verantwortung übernehmen, Durchhaltevermögen haben und durchsetzungsstark sein.',
  Wirtschaft:
    'Im Bereich Wirtschaft geht es um die Verbindung von Technik und Wirtschaft, was ein Verständnis beider Welten erfordert. Die Grundlage ist ein Interesse an Zahlen, Fakten und Wirtschaft. Wer sich mündlich und schriftlich gut ausdrücken kann und selbständig arbeitet, ist hier im Vorteil.',
};

export const getResultIcon = name => {
  switch (name) {
    case 'Entrepreneur':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Unternehmertum.png';
      break;
    case 'Handel':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Handel.png';
      break;
    case 'Handwerk':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Handwerk.png';
      break;
    case 'Informatik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Informatik.png';
      break;
    case 'Kreative Technik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Kreative_Technik.png';
      break;
    case 'Landwirtschaft':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Landwirtschaft.png';
      break;
    case 'Mathematik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Mathematik.png';
      break;
    case 'Medientechnik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Medientechnik.png';
      break;
    case 'Medizinische Technik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Medizinische_Technik.png';
      break;
    case 'Naturwissenschaft':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Naturwissenschaft.png';
      break;
    case 'Naturwissenschaften':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Naturwissenschaft.png';
      break;
    case 'Technik':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Technik.png';
      break;
    case 'Unternehmertum':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Unternehmertum.png';
      break;
    case 'Wirtschaft':
      return 'https://www.coaching4future.de/dest/images/c4f/karrierenavigator/Wirtschaft.png';
      break;
    default:
      break;
  }
};
