import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion/dist/framer-motion';

export const ResultsCard = (
  props = {
    url,
    label: null,
    content: '',
    active: null,
    disabled,
    animate: true,
    delayActivateAnimation: null,
    onClick: () => {},
    clickable,
  },
) => {
  const theme = useTheme();
  const clickable = props.clickable || props.url;

  let content = props.content ? { ...props.content } : null;
  if (content) {
    content.props = {
      ...content.props,
      sx: {
        height: '100%',
      },
    };
  }

  const onClick = url => {
    props.onClick;

    url && window.open(url, '_blank').focus();
  };

  const children = (
    // <CardContent sx={{ px: 3, py: 3 }}>
    // <CardContent sx={{ p: 0, pb: 0 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <>{content}</>
    </Box>
    // </CardContent>
  );

  return (
    <>
      <MuiCard
        elevation={0}
        sx={{ background: 'rgba(0,0,0,0)', overflow: 'visible' }}
      >
        {/* active animation */}
        <motion.div
          initial={{
            scale: 1,
            // background: theme.palette.grey[200],
          }}
          animate={() => {
            if (props.active) {
              // Active
              return {
                scale: [1, 0.98, 1.025, 1],
                // background: theme.palette.secondary.main,
                transition: {
                  duration: props.animate ? 0.4 : 0.01,
                  delay: (props.animate && props.delayActivateAnimation) || 0,
                },
              };
            } else if (props.disabled) {
              return {
                scale: [1, 0.98],
                opacity: 0.5,
                transition: {
                  duration: 0.2,
                  delay: 0,
                },
              };
            } else {
              // Normal
              return {
                scale: 1,
                // background: theme.palette.grey[200],
                transition: {
                  duration: 0.2,
                  delay: 0,
                },
              };
            }
          }}
        >
          {clickable ? (
            <CardActionArea
              disabled={props.disabled}
              onClick={() => onClick(props.url)}
              aria-label={props.label}
              children={children}
              sx={{ p: 2 }}
            />
          ) : (
            children
          )}
        </motion.div>
      </MuiCard>
    </>
  );
};

export default ResultsCard;
