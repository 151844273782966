import React from 'react';
import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import getIcon from '../../utils/getIcon';
import { DottedBackground } from './dottedBackground';

const IconAnimation = (props = { show, width, height }) => {
  let Icon = { ...getIcon(props.icon) };

  // Modify icon props
  if (Icon) {
    Icon.props = {
      ...Icon.props,
      sx: {
        width: '100%',
        height: '100%',
        position: 'relative',
      },
    };
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'center',
        position: 'relative',
        width: { xs: 160, sm: 200, md: 250 },
        height: { xs: 160, sm: 200, md: 250 },
      }}
    >
      {/* Background */}
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
        }}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: [0, 1.05, 0.95, 1] }}
            exit={{ opacity: 0, scale: 0 }}
            key={props.show}
          >
            {props.show && (
              <Box
                sx={{
                  borderRadius: '9999px',
                  overflow: 'hidden',
                  opacity: 0.2,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: DottedBackground,
                  }}
                />
              </Box>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Icon */}
      <Box
        sx={{
          position: 'absolute',
          height: '50%',
          width: '50%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{ scale: [0, 1.05, 0.95, 1] }}
            exit={{ scale: 0 }}
            transition={{ delay: 0.175 }}
            key={props.show}
          >
            {props.show && Icon}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default IconAnimation;
