import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

const getIndex = (items, id) => {
  let i = null;

  items.map((item, index) => {
    if (item.id === id) {
      i = index;
    }
  });

  return i;
};

export const Tabs = ({ items, activeItem }) => {
  const theme = useTheme();
  const [value, setValue] = useState(activeItem || items[0].id);
  const [tabIndex, setTabIndex] = useState();
  const [direction, setDirection] = useState();

  const handleChange = (event, newValue) => {
    if (getIndex(items, newValue) > getIndex(items, value)) {
      setDirection('next');
    } else {
      setDirection('back');
    }

    setTabIndex(newValue);
    setValue(newValue);
    setTimeout(() => {}, 200);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        {/* Tab navigation */}
        <Box
          sx={{
            borderBottom: 0,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            background: theme.palette.background.paper,
            zIndex: 1,
            height: 50,
          }}
        >
          <TabList onChange={handleChange} aria-label="Ergebnisse">
            {items.map((item, index) => {
              return <Tab key={index} label={item.label} value={item.id} />;
            })}
          </TabList>
        </Box>

        <Box>
          {/* Tab content */}
          {items.map((item, index) => {
            return (
              <TabPanel key={index} value={item.id} sx={{ p: 0 }}>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{
                      opacity: 0,
                      x: 0,
                      // x: direction === 'next' ? 10 : -10,
                      // scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      scale: 1,
                      transition: { delay: 0.2, duration: 0.2 },
                    }}
                    exit={{
                      opacity: 0,
                      x: 0,
                      // x: direction === 'next' ? 10 : -10,
                      // scale: 1,
                    }}
                    transition={{ duration: 0.2, delay: 0 }}
                    key={tabIndex}
                  >
                    <Box>{items[index].content}</Box>
                  </motion.div>
                </AnimatePresence>
              </TabPanel>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
};

export default Tabs;
