import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const coreThemeObj = {
  typography: {
    // fontFamily: ['"Poppins"', 'inherit'].join(','),
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    // fontSize: 22,
    fontWeight: 400,
    fontSize: 22,
    // fontSize: '62.5%',
    // fontSize: '1.4rem',
    lineHeight: 1.5,
    button: {
      fontWeight: 800,
      fontSize: 26,
      letterSpacing: '.1rem',
    },
  },
  palette: {
    primary: {
      // main: common.black,
      main: '#021F2C',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00EC89',
      // main: green.A400,
      // light: green.A200,
      // dark: green[500],
      contrastText: '#ffffff',
    },
    // success: {
    //   main: green.A400,
    // },
  },
  shape: { borderRadius: 0 },
  neon: { main: '#00EC8A' },
};

export const darkTheme = createTheme({
  ...coreThemeObj,
  palette: {
    ...coreThemeObj.palette,
    mode: 'dark',
  },
});

export const _lightTheme = createTheme({
  ...coreThemeObj,
  palette: {
    ...coreThemeObj.palette,
    mode: 'light',
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: themeParam => ({
    //     body: {
    //       backgroundColor: '#efefef',
    //     },
    //   }),
    // },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default
        // disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Button Variants
          ...(ownerState.variant === 'outlined' && {
            borderWidth: '3px !important',
            backgroundColor: 'transparent',

            '&:hover': {
              borderWidth: 3,
              // backgroundColor: coreThemeObj.palette.primary.main,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            ...(ownerState.color === 'primary' && {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: coreThemeObj.palette.primary.main,
                color: coreThemeObj.palette.primary.contrastText,
              },
            }),
            ...(ownerState.color === 'secondary' && {
              '&:hover': {
                backgroundColor: coreThemeObj.palette.secondary.main,
                color: coreThemeObj.palette.secondary.contrastText,
              },
            }),
          }),
          ...(ownerState.size === 'small' &&
            {
              // paddingTop: 16,
              // paddingBottom: 16,
            }),
          ...(ownerState.size === 'medium' && {
            paddingTop: 8,
            paddingBottom: 8,
            minHeight: 60,
          }),
          ...(ownerState.size === 'large' && {
            paddingTop: 20,
            paddingBottom: 20,
          }),
        }),
      },
      // variants: [
      // 	{
      // 		props: { variant: "outlined" },
      // 		style: { borderWidth: "2px !important" },
      // 	},
      // ],
    },
    // Name of the component
    MuiCard: {
      // defaultProps: {},
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: grey[200],
        },
      },
    },
  },
});

export const lightTheme = responsiveFontSizes(_lightTheme);
