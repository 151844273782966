export const findFirstFreeAnswer = questions => {
  const isArray = Array.isArray(questions);

  // Return value
  // contains the index/key of the first free answer
  let _index = undefined;

  // API data with objects
  Object.keys(questions).map(key => {
    if (!questions[key].current_value && _index === undefined) {
      _index = String(key);
    }
  });

  // if (!_index) {
  // 	// _index = String(Object.entries(questions)[0][0]);
  // }

  return _index;
};

export const getQuestionPagination = (questions, questionKey = null) => {
  let pagination = {
    total: Object.keys(questions).length,
    index: null,
    previousAnswer: null,
    nextAnswer: null,
    nextFreeAnswer: null,
    answersCount: 0,
  };

  Object.keys(questions).map((key, index) => {
    if (questionKey && String(questionKey) === String(key)) {
      const nextAnswer = Object.entries(questions)[index + 1]?.[1].uid;
      const prevAnswer = Object.entries(questions)[index - 1]?.[1].uid;

      pagination.previousAnswer = prevAnswer;
      pagination.nextAnswer = nextAnswer;
      pagination.index = index;
    }

    if (questions[key].current_value) {
      pagination.answersCount = pagination.answersCount + 1;
    } else {
      pagination.nextFreeAnswer = key;
    }
  });

  return pagination;
};

export const getProgress = topics => {
  let totalQuestions = 0;
  let totalAnswers = 0;
  let progress = 0;

  Object.keys(topics).map((key, index) => {
    const questionGroups = topics[key].question_groups;

    questionGroups &&
      Object.keys(questionGroups).map((key, index) => {
        totalAnswers = totalAnswers + Number(questionGroups[key].answered);
        totalQuestions =
          totalQuestions + Number(questionGroups[key].question_amount);
      });
  });

  progress = Math.floor((totalAnswers / totalQuestions) * 100);

  return progress;
};

export const isTopicComplete = (topics, index) => {
  let complete = null;

  Object.keys(topics).map((key, _index) => {
    if (index === _index) {
      const topic = topics[key];
      const questionGroups = topic.question_groups;
      const questionGroupsTotal = Object.keys(questionGroups).length;
      let questionGroupsComplete = 0;

      Object.keys(questionGroups).map((key, index) => {
        const questionGroup = questionGroups[key];
        const answers = Number(questionGroup.answered);
        const totalAnswers = Number(questionGroup.question_amount);

        if (answers === totalAnswers) {
          // Question group is complete - count up
          questionGroupsComplete = questionGroupsComplete + 1;
        }
      });

      if (questionGroupsTotal === questionGroupsComplete) {
        // All question groups of current topic are complete
        complete = true;
      }
    }
  });

  return Boolean(complete);
};

/**
 * Get complete topics as array
 */
export const getCompleteTopics = topics => {
  let completeTopics = [];

  Object.keys(topics).map(key => {
    let q = 0; // Questions amount
    let a = 0; // Answers amount

    // Count through all questions
    Object.keys(topics[key].question_groups).map(uid => {
      const group = topics[key].question_groups[uid];

      q += Number(group.question_amount);
      a += Number(group.answered);
    });

    if (q === a) {
      completeTopics.push(String(topics[key].uid));
    }
  });

  return completeTopics;
};

export const allTopicsComplete = topics => {
  const topicsAmount = Object.keys(topics).length;
  const completedTopicsAmount = getCompleteTopics(topics).length;
  const allTopicsComplete = completedTopicsAmount === topicsAmount;

  return allTopicsComplete;
};

export const allTopicsNotComplete = topics => {
  let notCompleteTopics = [];

  Object.keys(topics).map((key, index) => {
    if (!getCompleteTopics(topics).includes(key)) {
      notCompleteTopics.push(key);
    }
  });

  return notCompleteTopics;
};

export const arraysEqual = (a, b, sort = true) => {
  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  let x = sort ? a.sort() : a;
  let y = sort ? b.sort() : b;

  if (x === y) return true;
  if (x == null || y == null) return false;
  if (x.length !== y.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (x[i] !== y[i]) return false;
  }
  return true;
};

export const setUserUrlParameter = uid => {
  let queryParams = new URLSearchParams(window.location.search);

  // Set new or modify existing page value
  queryParams.set('uid', uid);

  // Replace current querystring with the new one
  history.replaceState(null, null, '?' + queryParams.toString());
};
