import React, { useState, useEffect, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import {
  Box,
  Stack,
  Button,
  Typography,
  Modal
} from '@mui/material';
import getIcon from '../karrierenavigator/Stepper/utils/getIcon';

let paperplaneIcon = { ...getIcon('paperplane') };

paperplaneIcon.props = {
  sx: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'secondary.light',
  boxShadow: 24,
  p: 4,
  // minHeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

/**
 * Shows a confirmation Dialog with a headline and simple YES/NO Buttons
 *
 * Usage:
 *     1. Define a state in your parent Component in which you want to use this
 *     2. Trigger the state on a Button or Link (ex: <code>onClick={() => setConfirmOpen(true)}</code>)
 *     3. Use the component and don't forget to give some callbacks which also resets the state
 *
 * @example
 * <ConfirmDialog
 *    headline="Alternate headline possible"
 *    description="A description additional to the headline"
 *    open={confirmOpen}
 *    onConfirm={() => { setConfirmOpen(false); setStepIndex && setStepIndex('start'); }}
 *    onCancel={() => setConfirmOpen(false)}
 *  />
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ConfirmDialog = (props = { callbackFn }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.onCancel && props.onCancel();
  };

  const handleConfirm = useCallback(() => {
    setOpen(false);
    props.onConfirm();
  }, [props.onConfirm]);

  useEffect(() => {
    props.open && handleOpen();
  }, [props.open]);

  return (
    <Box display="flex" justifyContent="center">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" noValidate autoComplete="off">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              style={{ width: '100%' }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                component="h4"
                textAlign="center"
                sx={{ mb: 2 }}
              >
                {props.headline || "Bist du sicher?"}
              </Typography>
              {props.description || (<Typography textAlign="left" pb={2}>Wenn du fortfährst, werden alle Daten unwiderruflich gelöscht. Eine Rückkehr mit Karriere-Code ist dann nicht mehr möglich.</Typography>)}
              <Stack direction="row-reverse" spacing={2}>
                <Button variant={'outlined'} onClick={handleClose}>Nein</Button>
                <Button variant={'primary'} onClick={handleConfirm}>Ja</Button>
              </Stack>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Modal>
    </Box>
  );
};

export default ConfirmDialog;
