import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  // Legend,
  LinearScale,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';

function hexToRgbA(hex, opacity) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  // Tooltip,
  LinearScale,
  // Legend
);

const RadarChart = (
  props = { chartData, labels, tension, activeIndex, animations },
) => {
  const theme = useTheme();
  const defaultData = {
    // labels: props.labels.map(() => ''),
    labels: props.labels,
    datasets: [
      {
        data: props.chartData,
        backgroundColor: hexToRgbA(theme.palette.secondary.main, 0.125),
        borderColor: hexToRgbA(theme.palette.secondary.main, 1),
        borderWidth: 2,
        borderColor: hexToRgbA(theme.palette.secondary.main, 0.5),
      },
    ],
  };
  const [data, setData] = useState(defaultData);
  const [show, setShow] = useState();

  // Global options
  const options = {
    responsive: true,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
        label: {
          display: false,
        },
        ticks: {
          // forces step size to be 50 units
          stepSize: 50,
          // Hide step labels
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: props.tension || 0,
      },
      point: {
        // pointRadius: 4,
        pointRadius: function (context) {
          const index = context.dataIndex;
          return index === Number(props.activeIndex) ? 8 : 4;
        },
        // pointHoverRadius: 10,
        pointBorderWidth: 2,
        pointBorderColor: theme.palette.secondary.main,
        // pointHoverBorderWidth: 12,
        // pointHoverBorderColor: hexToRgbA(theme.palette.secondary.main, 0.25),
        // pointBackgroundColor: theme.palette.secondary.dark,
        pointBackgroundColor: function (context) {
          const index = context.dataIndex;
          return index === Number(props.activeIndex)
            ? theme.palette.secondary.main
            : 'transparent';
        },
        // pointHoverBackgroundColor: theme.palette.secondary.dark,
      },
    },

    animation: props.animations,
    plugins: {
      datalabels: {
        display: true,
      },
      // tooltip: {
      //   usePointStyle: true,
      //   callbacks: {
      //     // afterLabel: () => {
      //     //   return '%';
      //     // },
      //     // labelPointStyle: function (context) {
      //     //   return {
      //     //     pointStyle: 'triangle',
      //     //     rotation: 0,
      //     //   };
      //     // },
      //     labelColor: function (context) {
      //       return {
      //         borderColor: 'transparent',
      //         backgroundColor: 'transparent',
      //         borderWidth: 0,
      //         // borderDash: [2, 2],
      //         borderRadius: 0,
      //         width: 0,
      //       };
      //     },
      //     label: function (context) {
      //       return context.formattedValue + '%';
      //     },
      //   },
      // },
    },
  };

  // Set options for animating the content
  useEffect(() => {
    if (props.show === true) {
      // let tempData = { ...defaultData };
      // tempData.datasets = [
      //   {
      //     // label: '# of Votes',
      //     data: props.chartData,
      //     backgroundColor: hexToRgbA(theme.palette.secondary.main, 0.125),
      //     borderColor: hexToRgbA(theme.palette.secondary.main, 1),
      //     borderWidth: 2,
      //     borderColor: hexToRgbA(theme.palette.secondary.main, 0.5),
      //   },
      // ];
      // if (show === undefined) {
      //   // setTimeout(() => {}, 200);
      //   setShow(true);
      //   setData(tempData);
      // } else {
      //   setData(tempData);
      // }
    } else {
      // let tempData = { ...defaultData };
      // tempData.datasets = [
      //   {
      //     // label: '# of Votes',
      //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      //     backgroundColor: hexToRgbA(theme.palette.secondary.main, 0),
      //     borderColor: hexToRgbA(theme.palette.secondary.main, 1),
      //     borderWidth: 2,
      //     borderColor: hexToRgbA(theme.palette.secondary.main, 0.5),
      //   },
      // ];
      // setShow(false);
      // setData(tempData);
    }
  }, [props.show, props.chartData]);

  return <Radar data={data} options={options} />;
};

export default RadarChart;
