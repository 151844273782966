import React, { useContext, useState } from 'react';
import {
  Grid,
  IconButton,
  Box,
  Typography,
  Button,
  Stack,
  TextField,
  Alert,
  Snackbar
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import HomeIcon from '@mui/icons-material/Home';
import { CloseIcon } from '../Icons';

import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import StepperContext from '../StepperContext';
import { Default } from '../StepUserCode/StepUserCode.stories';

const CssTextField = styled(TextField)({
  flexGrow: 1,

  '& .MuiInputBase-root': {
    color: 'white',
    height: '100%',
  },
});

const Header = ({ initial, onClose, onHome, homeButton, showTitle }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { user, currentIndex } = useContext(StepperContext);
  const [snackbarClipboardOpen, setSnackbarClipboardOpen] = useState(false);

  const handleCopyToClipboard = string => {
    navigator.clipboard.writeText(string);
    setSnackbarClipboardOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarClipboardOpen(false);
  };

  const showUserCode = !['start','introQuestions'].includes(currentIndex);

  //Close Button with new confirmation dialog (i know this solution is a bit creepy)
  const closeButton = () => {
    let descriptionContent = ( !['start','introQuestions'].includes(currentIndex) ) ? (
      <>
        <Typography sx={{ mb: 2 }}>
          Du kannst jederzeit zurückkehren, mit deinem Karriere-Code:
        </Typography>
        <Stack spacing={2} pb={2}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
          }}>
            <CssTextField
              value={user || ''}
              onClick={() => handleCopyToClipboard(user)}
              sx={{ backgroundColor: 'primary.main' }}
            />
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                handleCopyToClipboard(user);
              }}>
              <ContentCopyIcon />
            </Button>
          </Box>
        </Stack>
        <Snackbar
          open={snackbarClipboardOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
            {Default.args.copiedCodeConfirmation}
          </Alert>
        </Snackbar>
      </>
    ) : (<></>);

    return (<>
      <IconButton
        aria-label="Karrierenavigator schließen"
        size="large"
        sx={{
          mr: -1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setConfirmOpen(true) }
      >
        <CloseIcon sx={{ visibility: 'hidden' }} />
        <CloseIcon sx={{ maxHeight: 24, maxWidth: 24, position: 'absolute' }} />
      </IconButton>
      <ConfirmDialog
        headline={"Willst du schon gehen?"}
        description={descriptionContent}
        open={confirmOpen}
        onConfirm={() => onClose() }
        onCancel={() => setConfirmOpen(false)}
      />
    </>);
  }; // END: Close Button with confirmation dialog



  return (
    <Grid
      container
      alignItems="center"
      sx={{ px: 2, py: { xs: 1, md: 1 }, minHeight: { sm: 80 } }}
    >
      {/* Left area */}
      <Grid item flexGrow="1" sx={{ py: { xs: 0, md: 1 } }}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={initial}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {/* Logo */}
            {initial ? (
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item flexGrow="1">
                  <a href="#">
                    <Box sx={{ maxWidth: 120 }}>
                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={
                          initial && {
                            opacity: 1,
                            scale: 1,
                          }
                        }
                        exit={{
                          opacity: 0,
                          scale: 0,
                        }}
                        key={initial}
                      >
                      </motion.div>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent={'flex-end'}>
                  {/* Close button */}
                  {onClose && closeButton()}
                </Grid>
              </Grid>
            ) : (
              // Home  button + title
              <AnimatePresence exitBeforeEnter>
                <Grid container sx={{ alignItems: 'center' }}>
                  {/* Home button */}
                  <Grid item xs={2}>
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={
                          homeButton && {
                            opacity: 1,
                            scale: 1,
                            transition: { delay: 0.25 },
                          }
                        }
                        exit={{
                          opacity: 0,
                          scale: 0,
                          transition: { delay: 0.25 },
                        }}
                        key={homeButton}
                      >
                        <IconButton
                          aria-label="Zurück zur Übersicht"
                          size="large"
                          onClick={onHome}
                        >
                          <HomeIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                        {/* <Link */}
                        {/*   fontWeight="bold" */}
                        {/*   underline="none" */}
                        {/*   component="button" */}
                        {/*   variant="caption" */}
                        {/*   onClick={onHome} */}
                        {/* > */}
                        {/*   Zurück zur Übersicht */}
                        {/* </Link> */}
                      </motion.div>
                    </AnimatePresence>
                  </Grid>

                  {/* Title */}
                  <Grid item flexGrow="1">
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={
                          showTitle && {
                            opacity: 1,
                            scale: 1,
                            transition: { delay: 0.75 },
                          }
                        }
                        exit={{
                          opacity: 0,
                          scale: 0,
                          transition: { delay: 0.25 },
                        }}
                        key={showTitle}
                      >
                        <Typography
                          textAlign={'center'}
                          variant="h6"
                          component="h2"
                        >
                          Karrierenavigator
                        </Typography>
                      </motion.div>
                    </AnimatePresence>
                  </Grid>

                  <Grid item xs={2} display="flex" justifyContent={'flex-end'}>
                    {/* Close button */}
                    {onClose && closeButton()}
                  </Grid>
                </Grid>
              </AnimatePresence>
            )}
          </motion.div>
        </AnimatePresence>
      </Grid>
    </Grid>
  );
};

export default Header;
