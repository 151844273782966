import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import Header from './Header/Header';
import StepInit from './StepInit/StepInit';
import StepperContext from './StepperContext';
import StepOverview from './StepOverview/StepOverview';
import StepSurvey from './StepSurvey/StepSurvey';
import StepQuestionsComplete from './StepQuestionsComplete/StepQuestionsComplete';
import StepQuestions from './StepQuestions/StepQuestions';
import StepResults from './StepResults/StepResults';
import StepUserCode from './StepUserCode/StepUserCode';
import StepFinal from './StepFinal/StepFinal';
import StepExplanation from './StepExplanation/StepExplanation';
import { Default as StepQuestionsDefault } from './StepQuestions/StepQuestions.stories';
import { useTheme } from '@mui/material/styles';
import { getCurrentUser, loginUser } from './utils/api';
import { setUserUrlParameter } from './utils/helper';
import { DialogContext } from './StepperContext';
import useMatomo from './utils/tracking';

const Stepper = (
  props = {
    mockData,
    stepIndex,
    withDialogHeader,
    handleClose,
    topics,
    apiUrl,
    user,
    debug,
    desktopMode,
  },
) => {
  const dialogContext = useContext(DialogContext);

  // Component Props
  const desktopMode = props.desktopMode;
  const mockData = props.mockData;
  const withDialogHeader = props.withDialogHeader || null;
  const [currentIndex, setStepIndex] = useState(props.stepIndex || 0);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(props.user || null);
  const [topic, setTopic] = useState();
  const [topicsHistory, setTopicsHistory] = useState();
  const [autoRedirect, setAutoRedirect] = useState();
  const [questionGroup, setQuestionGroup] = useState();

  const { trackEvent } = useMatomo();

  // Mui theme
  const theme = useTheme();

  // For storybook live editing
  useEffect(() => {
    setStepIndex(props.stepIndex);
    dialogContext?.setScreenKey && dialogContext.setScreenKey(props.stepIndex);
  }, [props.stepIndex]);

  const [stepperContent, setStepperContent] = useState();
  const debug = props.debug;

  const afterLoginHandler = uid => {
    setUser(uid);
    setLoading(false);

    if (props.stepIndex !== 'userCode') {
      setStepIndex('overview');
    }

    dialogContext?.setUser(uid);
    dialogContext?.setScreenKey(currentIndex);

    setUserUrlParameter(uid);
  };

  const userLoginHandler = uid => {
    // Api query for user
    loginUser(uid)
      .then(res => {
        res.user && afterLoginHandler(res.user);
      })
      .catch(e => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    if (!mockData) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      const paramUid = currentUrlParams.get('uid');

      if (paramUid) {
        debug && console.log('uid parameter found in url:\n', paramUid);

        userLoginHandler(paramUid);
      } else {
        // Query user session (cookie 'fe_user_type')
        getCurrentUser()
          .then(res => {
            if (res.user) {
              afterLoginHandler(res.user);
            } else {
              setLoading(false);
            }
          })
          .catch(e => {
            console.log(e.message);
          });
      }
    } else if (user) {
      // Using mock data
      setLoading(false);
      setUser(user);
    } else {
      // setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isNaN(currentIndex)) {
      switch (currentIndex) {
        case 'start': {
          setStepperContent(
            <StepInit
              title="Check dich selbst!"
              slug="check-dich-selbst"
              subline="Finde heraus, welcher MINT-Beruf zu dir passt."
              buttonLabel="Los Geht's"
              icon="paperplane"
              redirectIndex="introQuestions"
            />,
          );
          break;
        }
        case 'introQuestions': {
          setStepperContent(<StepQuestions {...StepQuestionsDefault.args} />);
          break;
        }
        case 'userCode': {
          setStepperContent(<StepUserCode />);
          break;
        }
        case 'explanation': {
          setStepperContent(<StepExplanation />);
          break;
        }
        case 'overview': {
          setStepperContent(
            <StepOverview unlockTopicsMode={true} desktopMode={desktopMode} />,
          );
          break;
        }
        case 'questionGroup': {
          setStepperContent(<StepSurvey />);
          break;
        }
        case 'questionGroupComplete': {
          setStepperContent(
            <StepQuestionsComplete
              redirectIndex="overview"
              subline="Prima!"
              text="Du hast alle Fragen in diesem Block beantwortet."
              buttonLabel="Weiter"
            />,
          );
          break;
        }
        case 'topicComplete': {
          setStepperContent(
            <StepQuestionsComplete
              redirectIndex="results"
              skipIndex="overview"
              headline="Sehr gut!"
              text="Du hast den ersten Teil erfolgreich abgeschlossen. Wir zeigen dir jetzt, wie verschiedene Berufsbereiche zu deinen Interessen passen."
              buttonLabel="Zwischenergebnis anzeigen"
            />,
          );
          break;
        }
        case 'allTopicsComplete': {
          setStepperContent(
            <StepQuestionsComplete
              redirectIndex="resultsFinal"
              skipIndex="overview"
              headline="Gut gemacht!"
              text="Puh, das wars jetzt aber auch mit den Fragen. Schau dir jetzt dein Ergebnis an."
              buttonLabel="Ergebnisse anzeigen"
            />,
          );
          trackEvent(null, 'Finished', 'allTopicsComplete');
          break;
        }
        case 'results': {
          setStepperContent(
            <StepResults
              headline="Zwischenergebnis"
              text="<p>Das Diagramm zeigt dir, welche Berufsbereiche am besten mit deinen Interessen übereinstimmen. Je höher die Prozentzahl, desto höher ist die Übereinstimmung mit deinen Interessen.</p><p>Über die kleinen Pfeile kannst du dir alle zwölf Berufsbereiche ansehen.</p>"
              button={{
                label: 'Zurück zur Übersicht',
                redirect: 'overview',
              }}
            />,
          );
          break;
        }
        case 'resultsFinal': {
          setStepperContent(
            <StepResults
              finalResult={true}
              headline="Dein Profil"
              button={{
                label: 'Zurück zur Übersicht', // label: 'Weiter',
                redirect: 'overview', // redirect: 'start',
              }}
            />,
          );
          break;
        }
        case 'final': {
          setStepperContent(<StepFinal />);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [currentIndex]);

  const greenIndex = [
    'start',
    'introQuestions',
    'userCode',
    'explanation',
    'questionGroupComplete',
    'topicComplete',
    'allTopicsComplete',
    'final',
  ];

  const closeDialogHandler = () => {
    props.handleClose();
  };

  return (
    !loading && (
      <Box
        sx={{
          minHeight: '100%',
          display: 'flex',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <StepperContext.Provider
          value={{
            mockData: mockData,
            apiUrl: props.apiUrl,
            debug: props.debug,
            currentIndex: currentIndex,
            setStepIndex: setStepIndex,
            // context: context,
            // stepperContent: stepperContent,
            setStepperContent: setStepperContent,
            user: user,
            setUser: setUser,
            loading: loading,
            setLoading: setLoading,
            topicsHistory: topicsHistory,
            setTopicsHistory: setTopicsHistory,
            topic: topic,
            setTopic: setTopic,
            questionGroup: questionGroup,
            setQuestionGroup: setQuestionGroup,
            autoRedirect: autoRedirect,
            setAutoRedirect: setAutoRedirect,
          }}
        >
          {/* Background */}
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
              animate={() => {
                if (greenIndex.includes(currentIndex)) {
                  return {
                    background: theme.palette.secondary.main,
                  };
                } else {
                  return {
                    background: theme.palette.common.white,
                  };
                }
              }}
              transition={{ duration: 0.5, delay: 0.5 }}
              // key={currentIndex}
            ></motion.div>
          </AnimatePresence>

          <AnimatePresence exitBeforeEnter>
            <Stack
              sx={{
                zIndex: 2,
                p: 0,
                // height: "100%",
                width: '100%',
                overflow: 'hidden',
              }}
            >
              {/* Header */}
              {/* {withDialogHeader && ( */}
              <Box>
                <Header
                  initial={['start', 'introQuestions'].includes(currentIndex)}
                  onClose={withDialogHeader && closeDialogHandler}
                  showTitle={!['questionGroupComplete'].includes(currentIndex)}
                  homeButton={['questionGroup', 'results', 'resultsFinal'].includes(currentIndex)}
                  onHome={() => setStepIndex('overview')}
                />
              </Box>
              {/* )} */}

              {/* Content */}
              <Box sx={{ flexGrow: 1 }}>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{ opacity: 0, x: 20, scale: 1, height: '100%' }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      scale: 1,
                      transition: {
                        delay: 0,
                        duration: 0.5,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      x: -20,
                      scale: 1,
                      transition: {
                        delay: 0.5,
                        duration: 0.25,
                      },
                    }}
                    key={currentIndex}
                  >
                    {stepperContent}
                  </motion.div>
                </AnimatePresence>
              </Box>
            </Stack>
          </AnimatePresence>
        </StepperContext.Provider>
      </Box>
    )
  );
};

export default Stepper;
