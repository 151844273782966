import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Grid,
  IconButton,
  Container,
  Box,
  Stack,
  Button,
  Typography,
  Card,
  CardActionArea,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

const AnimatedButton = props => {
  const [show, setShow] = useState(
    props.show !== undefined ? props.show : true,
  );
  const [active, setActive] = useState(props.active);
  const [clicked, setClicked] = useState(0);

  useEffect(() => {
    props.show !== undefined && setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        onClick={props.onClick}
        initial={{ opacity: 0, x: 20 }}
        animate={show && { opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.2 }}
        key={show}
      >
        {/* {props.show && ( */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{
              scale: props.activeAnimation ? 1 : 1.05,
              position: 'relative',
            }}
            animate={() => {
              if (active) {
                return {
                  scale: 1.05,
                  transition: { type: 'spring', stiffness: 500 },
                };
              } else if (props.disabled) {
                return { scale: 0.95, opacity: 0.5 };
              }
            }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            {/* Click Wrapper */}
            <motion.div
              initial={{
                scale: 1,
              }}
              // animate={{
              // 	scale: [1, 1.025, 1],
              // 	transition: {
              // 		duration: 0.2,
              // 	},
              // }}
              transition={{ type: 'spring', stiffness: 500 }}
            >
              <Card elevation={0}>
                <motion.div
                  initial={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    background: 'black',
                    color: 'white',
                    opacity: 0,
                  }}
                  animate={active ? { opacity: 1 } : { opacity: 0 }}
                  transition={{ delay: 0, duration: 0.3 }}
                ></motion.div>

                <CardActionArea
                  disabled={props.disabled}
                  sx={{ zIndex: 2, p: 0 }}
                  onClick={() => {
                    setClicked(clicked + 1);
                    (props.deactivate || !active) && setActive(!active);
                  }}
                >
                  <Button
                    component="div"
                    variant={props.variant}
                    color={'primary'}
                    fullWidth
                    sx={{ color: active && 'white' }}
                  >
                    {props.children}
                  </Button>
                </CardActionArea>
              </Card>
            </motion.div>
          </motion.div>
        </AnimatePresence>
        {/* )} */}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatedButton;
