import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import Lottie from 'react-lottie-player';
import buttonIcon_01 from '../../assets/C4F_Icon_01.json';
import buttonIcon_02 from '../../assets/C4F_Icon_02.json';
import buttonIcon_03 from '../../assets/C4F_Icon_03.json';
import buttonIcon_04 from '../../assets/C4F_Icon_04.json';
import buttonIcon_05 from '../../assets/C4F_Icon_05.json';

const buttons = {
  '🙁': {
    lottieJson: buttonIcon_05,
    label: 'Nein',
  },
  '😕': {
    lottieJson: buttonIcon_04,
    label: 'Eher nein',
  },
  '😐': {
    lottieJson: buttonIcon_03,
    label: 'Mittel',
  },
  '🙂': {
    lottieJson: buttonIcon_02,
    label: 'Eher ja',
  },
  '😃': {
    lottieJson: buttonIcon_01,
    label: 'Ja',
  },
};

export const AnswerButtons = (
  props = {
    showLabels,
    onClick: null,
    question,
    sortReverse: Boolean,
  },
) => {
  const values = props.question.values || null;
  let valueKeys = Object.keys(values);

  if (props.sortReverse && values) {
    valueKeys = Object.keys(values).reverse();
  }

  const current_value = props.question.current_value;

  const theme = useTheme();
  const [activeItems, setActiveItems] = useState();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    props.activeIndexes && setActiveItems([...String(props.activeIndexes)]);
  }, [props.activeIndexes]);

  useEffect(() => {
    setActiveItems([String(current_value)]);
  }, []);

  const clickHandler = key => {
    setActiveItems([String(key)]);
    setPlay(true);
    props.onClick && props.onClick(key);
  };

  return (
    <Grid container spacing={{ xs: '1vw', sm: 1, md: 2 }}>
      {/* active animation */}
      {values &&
        valueKeys.map((key, index) => {
          const id = values[key].id;
          const isActive = activeItems?.includes(String(id));
          // console.log(key, index);
          return (
            <Grid item xs={12} key={index}>
              <motion.div
                initial={{
                  scale: 1,
                  background: isActive
                    ? theme.palette.secondary.main
                    : theme.palette.grey[200],
                }}
                animate={() => {
                  if (isActive) {
                    return {
                      scale: play ? [1, 1.025, 1] : 1,
                      background: theme.palette.secondary.main,
                      transition: play && {
                        duration: 0.5,
                        delay: 0.2,
                      },
                    };
                  } else {
                    return {
                      scale: [1, 0.98, 1],
                      background: theme.palette.grey[200],
                      transition: {
                        duration: 0.2,
                      },
                    };
                  }
                }}
              >
                <Card elevation={0} sx={{ background: 'rgba(0,0,0,0)' }}>
                  <CardActionArea
                    disableRipple
                    component="div"
                    onClick={() => clickHandler(id)}
                  >
                    <CardContent sx={{ p: 0 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: 60, sm: 75, md: 100 },
                            height: { xs: 60, sm: 75, md: 100 },
                          }}
                        >
                          <Lottie
                            direction={1}
                            speed={parseInt(30, 10) / 20}
                            goTo={0}
                            loop={false}
                            // animationData={buttons[index].lottieJson}
                            animationData={buttons[values[id].title].lottieJson}
                            play={play && isActive}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </Box>

                        <AnimatePresence exitBeforeEnter>
                          <motion.div
                            style={{ position: 'relative' }}
                            initial={{
                              opacity: 0,
                              x: -10,
                            }}
                            animate={
                              props.showLabels
                                ? {
                                    opacity: 1,
                                    x: 0,
                                  }
                                : {
                                    opacity: 0,
                                    x: 0,
                                  }
                            }
                            exit={{
                              opacity: 0,
                              x: -10,
                            }}
                            key={props.showLabels}
                            transition={{ duration: 0.2, delay: 0 }}
                          >
                            <Box
                              component="span"
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: 'calc(50% + 20px)',
                                transform: 'translate(0%, -50%)',
                                width: 100,
                              }}
                            >
                              <Typography fontWeight="bold">
                                {buttons[values[id].title].label}
                              </Typography>
                            </Box>
                          </motion.div>
                        </AnimatePresence>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default AnswerButtons;
