const domain = window.location.host;
const isLive = domain === 'www.coaching4future.de';
const apiUrl = isLive
  ? window.location.origin + '/'
  : ( (window.location.origin.search('localhost') > 0) ? 'http://localhost:3000/proxy/api/' : 'https://c4f-staging.rsm-demo.de/' );

const basePath = 'api/data/';
const apiBase = apiUrl + basePath;

// Transport cookies through the proxy for local development
let requestOptions = {}
if ( !isLive && (window.location.origin.search('localhost') > 0) ) {
  requestOptions = {
    credentials: 'include'
  }
}

// Get current user
// Query user session (cookie 'fe_user_type')
export const getCurrentUser = async () => {
  const response = await fetch(apiBase + 'getcurrentstudent', requestOptions);

  // Returns user ID or null
  return response.json();
};

// User Login
export const loginUser = async uid => {
  const response = await fetch(apiBase + 'getstudent/' + uid, Object.assign({}, requestOptions, {method: 'POST'}));

  // Returns user ID or null
  return response.json();
};

// Create user
export const createUser = async introAnswers => {
  const response = await fetch(apiBase + 'buildstudent/' + introAnswers, Object.assign({}, requestOptions, {method: 'POST'}));

  // Returns user ID or null
  return response.json();
};

// Delete current user
export const deleteUser = async () => {
  const response = await fetch(apiBase + 'removecurrentstudent', requestOptions);

  return response.json();
}

// Logout current user
export const logoutUser = async () => {
  const response = await fetch(apiBase + 'logoutcurrentuser', requestOptions);

  return response.json();
}

export const getTopics = async debug => {
  const response = await fetch(apiBase + 'topics', requestOptions);

  if (debug) {
    console.log('🔎 Query topics', apiBase + 'topics');
    console.log('⬇️ Topics response: ', response);
  }

  return response.json();
};

export const getQuestionGroup = async uid => {
  const response = await fetch(apiBase + 'questions/' + uid, requestOptions);

  return response.json();
};

export const postQuestionGroup = async questionGroupApiBody => {
  const response = await fetch(apiBase + 'setanswers', Object.assign({}, requestOptions, {
    method: 'POST',
    body: JSON.stringify({
      ...questionGroupApiBody,
    }),
  }));

  return response.json();
};

export const getResults = async uid => {
  const response = await fetch(apiBase + 'getresults/' + uid + '?no_cache=1', requestOptions);

  return response.json();
};
