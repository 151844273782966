import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Button, Typography } from '@mui/material';
import { purple, secondary } from '@mui/material/colors';
import { _lightTheme } from '../karrierenavigator/theme';

const BootstrapButton = styled(Button)({
  width: 10,
  height: 10,
  borderRadius: '999px',
  backgroundColor: '#00EC89',
  textTransform: 'none',
  fontFamily: ['Times New Roman', 'Serif'].join(','),
  color: _lightTheme.palette.text.primary,

  // boxShadow: 'none',
  textTransform: 'none',
  fontSize: 30,
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  backgroundColor: _lightTheme.palette.secondary.light,
  // borderColor: '#0063cc',
  '&:hover': {
    backgroundColor: _lightTheme.palette.secondary.main,
    // borderColor: '#0062cc',
    // boxShadow: 'none',
  },
  '&:active': {
    // boxShadow: 'none',
    backgroundColor: _lightTheme.palette.secondary.main,
    // borderColor: '#005cbf',
  },
  '&:focus': {
    // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const InfoButton = ({ onClick = () => {} }) => {
  return (
    <Box>
      <BootstrapButton
        variant="contained"
        // sx={{ backgroundColor: 'secondary.light' }}
        onClick={onClick}
      >
        <Typography component="span" variant="h5" fontWeight={800}>
          i
        </Typography>
      </BootstrapButton>
    </Box>
  );
};

export default InfoButton;
