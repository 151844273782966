import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

export const CardList = (
  props = {
    disableOnSelect: Boolean,
    items: Array,
  },
) => {
  const theme = useTheme();
  const [activeItems, setActiveItems] = useState([]);

  return (
    <Stack spacing={0} sx={{ pt: 3 }}>
      {props.items?.map((item, index) => {
        let card = { ...item };

        card.props = {
          ...card.props,
          animationDelay: 200, // 500 + index * 300,
          show: true,
          // onClick: () => setActiveItems([index]),
          active: activeItems.includes(index),
          animate: true,
          // disabled: props.disableOnSelect && activeItems.length >= 1,
        };

        return (
          <motion.div
            style={{ marginTop: 0 }}
            initial={{ opacity: 0, x: 0 }}
            animate={{
              opacity: 1,

              height: 'auto',
              x: 0,
              // transition: { delay: index * 0.15 },
            }}
            exit={{ opacity: 0, x: -20 }}
            key={index}
          >
            <Box sx={{ p: 0 }}>
              <Box
                sx={{
                  height: '1px',
                  backgroundColor: 'primary.main',
                  position: 'sticky',
                  top: 50,
                }}
              />
              <Box sx={{ py: 0 }}>{card}</Box>
            </Box>
          </motion.div>
        );
      })}
    </Stack>
  );
};

export default CardList;
