import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardActionArea, CardContent, Box, Grid } from '@mui/material';

import { motion } from 'framer-motion/dist/framer-motion';
import Lottie from 'react-lottie-player';
import buttonIcon_01 from './assets/C4F_Icon_01.json';
import buttonIcon_02 from './assets/C4F_Icon_02.json';
import buttonIcon_03 from './assets/C4F_Icon_03.json';
import buttonIcon_04 from './assets/C4F_Icon_04.json';
import buttonIcon_05 from './assets/C4F_Icon_05.json';

const buttons = {
  0: {
    value: 0,
    lottieJson: buttonIcon_05,
  },
  1: {
    value: 1,
    lottieJson: buttonIcon_04,
  },
  2: {
    value: 2,
    lottieJson: buttonIcon_03,
  },
  3: {
    value: 3,
    lottieJson: buttonIcon_02,
  },
  4: {
    value: 4,
    lottieJson: buttonIcon_01,
  },
};

export const RatingButtons = (
  props = {
    onClick: null,
    activeIndexes: Array,
  },
) => {
  const theme = useTheme();
  const [activeItems, setActiveItems] = useState();

  useEffect(() => {
    setActiveItems([...String(props.activeIndexes)]);
  }, [props.activeIndexes]);

  const clickHandler = key => {
    setActiveItems([String(key)]);
    props.onClick && props.onClick;
  };

  return (
    <Grid container spacing={2}>
      {/* active animation */}
      {Object.keys(buttons).map((key, index) => {
        return (
          <Grid item xs={12}>
            <motion.div
              initial={{
                scale: 1,
                background: theme.palette.grey[200],
              }}
              animate={() => {
                if (activeItems?.includes(key)) {
                  return {
                    scale: [1, 1.025, 1],
                    background: theme.palette.secondary.main,
                    transition: {
                      duration: 0.5,
                      delay: 0.2,
                    },
                  };
                } else {
                  return {
                    scale: [1, 0.98, 1],
                    background: theme.palette.grey[200],
                    transition: {
                      duration: 0.2,
                    },
                  };
                }
              }}
            >
              <Card elevation={0} sx={{ background: 'rgba(0,0,0,0)' }}>
                <CardActionArea
                  disableRipple
                  component="div"
                  onClick={() => clickHandler(key)}
                >
                  <CardContent sx={{ p: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Lottie
                        direction={1}
                        speed={parseInt(30, 10) / 20}
                        goTo={0}
                        loop={false}
                        animationData={buttons[key].lottieJson}
                        play={activeItems?.includes(key)}
                        style={{ width: 150, height: 100 }}
                      />
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </motion.div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RatingButtons;
