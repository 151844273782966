import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import { Button, Container, Box, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconAnimation from '../Partials/IconAnimation/IconAnimation';
import AnimatedButton from '../Partials/AnimatedButton/AnimatedButton';
import Login from '../Partials/Login/Login';
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog';
import { deleteUser } from '../utils/api';
import { setUserUrlParameter } from '../utils/helper';

const Final = (props = {}) => {
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(props.show !== undefined ? props.show : true);
    }, 100);
  }, [props.show]);

  const { setStepIndex, setUser } = useContext(StepperContext);

  const continueHandler = () => {
    setShow(false);
    setStepIndex && setStepIndex('overview');
  };

  const restartHandler = () => {
    setShow(false);
    setStepIndex && setStepIndex('introQuestions');
  };

  const loginCallback = user => {
    setUser && setUser(user);
    setTimeout(() => {
      setStepIndex('overview');
    }, 2000);
  };

  const confirmDialogTrueAction = () => {
    deleteUser()
      .then(res => { debug && console.log('Deleted current user'); })
      .catch(e => { debug && console.warn(e.message); });

    setConfirmOpen(false);
    setStepIndex && setStepIndex('start');
    setUserUrlParameter('');
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Stack sx={{ height: '100%' }}>
        {/* Headline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                // scale: 1,
                scale: 1,
              }
            }
            exit={{
              opacity: 0,
              // scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.2 }}
            key={show}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              component="h1"
              textAlign="center"
              sx={{ mb: 2 }}
            >
              Check es nochmal
            </Typography>
          </motion.div>
        </AnimatePresence>

        <Box flexGrow={1} sx={{ textAlign: 'center', my: 2 }}>
          <IconAnimation show={show} icon={'checklabel'} />
        </Box>

        {/* Subline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.4, delay: 0.3 }}
            key={show}
          >
            <Typography
              variant="h6"
              component="div"
              fontWeight={600}
              textAlign="center"
            >
              Du möchtest deine Antworten anpassen?
            </Typography>
            <Typography
              variant="h6"
              component="div"
              fontWeight={200}
              textAlign="center"
              sx={{ mb: 4 }}
            >
              Checke deine Eingaben lasse dir noch passendere Ergebnisse
              anzeigen.
            </Typography>
          </motion.div>
        </AnimatePresence>
        {/* Button */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.5 }}
            key={show}
          >
            <Stack spacing={2}>
              <AnimatedButton
                activeAnimation
                variant="contained"
                children={'Weiter'}
                show={show}
                onClick={continueHandler}
              />
              <Button fullWidth variant={'outlined'} onClick={() => setConfirmOpen(true)}>
                Neu starten
              </Button>
              <ConfirmDialog
                open={confirmOpen}
                onConfirm={() => confirmDialogTrueAction() }
                onCancel={() => setConfirmOpen(false)}
              />
            </Stack>
          </motion.div>
        </AnimatePresence>

        {/* Footer */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
                transition: { duration: 0.5, delay: 1 },
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.05, 0.8],
              transition: { duration: 0.3, delay: 0.4 },
            }}
            transition={{ delay: 1 }}
            key={show}
          >
            <Stack sx={{ py: 4 }}>
              <Login callbackFn={user => loginCallback(user)} />
            </Stack>
          </motion.div>
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

export default Final;
