import React, { useState, useEffect, useContext, useCallback } from 'react';
// import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Button, Typography } from '@mui/material';

// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
// import { RatingActive } from '../../Icons';
import Card from '../Card/Card';
// import Progress from '../Progress/Progress';
import ProgressCircle from '../../../../ProgressCircle/ProgressCircle';

// import CircularProgress, {
//   circularProgressClasses,
//   CircularProgressProps,
// } from '@mui/material/CircularProgress';

// import { styled } from "@mui/material/styles";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
// 	height: 10,
// 	borderRadius: 5,
// 	[`&.${linearProgressClasses.colorPrimary}`]: {
// 		backgroundColor:
// 			theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
// 	},
// 	[`& .${linearProgressClasses.bar}`]: {
// 		borderRadius: 5,
// 		backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
// 	},
// }));

export const ResultsCard = (
  props = {
    // Results props
    show,
    title,
    kicker,
    title,
    progress: 0,
    url,
    // Default Card props
    active: null,
    animate: true,
    delayActivateAnimation: null,
    onClick: null,
    animationDelay,
  },
) => {
  const [progress, setProgress] = useState(0);
  // const [show, setShow] = useState(props.show);
  // const { show } = props;

  // useEffect(() => {
  // 	if (show) {
  // 		setTimeout(() => {
  // 			setProgress(props.progress);
  // 		}, 800);
  // 		// }
  // 	}
  // }, []);

  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        setTimeout(() => {
          setProgress(props.progress);
        }, 500);
      }, props.delayActivateAnimation || 100);
    } else {
      setProgress(0);
    }
  }, [props.show]);

  return (
    <Card
      {...props}
      content={
        <Grid container>
          <Grid item xs={props.progress ? 10 : 12}>
            <Stack>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {props.kicker}
              </Typography>
              <Typography fontWeight="bold">{props.title}</Typography>
            </Stack>
          </Grid>
          {props?.progress && (
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <ProgressCircle
                progress={props.progress}
                show={props.show}
                animationDelay={props.animationDelay || 0}
                active={props.active}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default ResultsCard;
