// Convert data from api into data object for Chart.js
export const getRadarChartData = scales => {
  scales.sort((a, b) => {
    const titleA = a.title.toUpperCase(); // ignore upper and lowercase
    const titleB = b.title.toUpperCase(); // ignore upper and lowercase

    if (titleA < titleB) {
      return -1; //nameA comes first
    }
    if (titleA > titleB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });

  let chartProps = {
    data: [],
    labels: [],
  };

  scales.map(scale => {
    console.log(scale.percentage, Number(scale.percentage.toFixed(0)));

    chartProps.data.push(Math.floor(scale.percentage));
    chartProps.labels.push(scale.title);
  });

  return chartProps;
};

export const getResultsByType = (results, type) => {
  let result_ = null;
  results.map(result => {
    if (
      result.type === type &&
      (result.scales?.[0] || result.apprenticeships?.[0] || result.courses?.[0])
    ) {
      result_ = result;
    }
  });

  return result_;
};

const sortByPercentage = (arrayItemA, arrayItemB) => {
  if (arrayItemA.percentage < arrayItemB.percentage) {
    return 1;
  }

  if (arrayItemA.percentage > arrayItemB.percentage) {
    return -1;
  }

  return 0;
};

export const getTopFiveData = (results, desired_result) => {
  let data = [];
  const topCounter = 5;

  // Transform desired_result to API data and easier conditioning to filter only for desired results
  if ( desired_result === 'studienfaecher' ) {
    desired_result = Array.from(['courses']);
  } else if ( desired_result === 'ausbildungsberufe' ) {
    desired_result = Array.from(['apprenticeships']);
  } else {
    desired_result = Array.from(['apprenticeships', 'courses']);
  }

  Object.entries(results).map((key, index) => {
    console.log(desired_result.includes(key[0]));
    if ( Array.isArray(key[1]) && desired_result.includes(key[0]) ) {
      const type = key[0];

      key[1].map((key, index) => {
        if (index < topCounter) {
          let obj = {
            ...key,
            type: type === 'courses' ? 'Studium' : 'Ausbildung',
          };

          data.push(obj);
        }
      });
    }
  });

  data.sort(sortByPercentage).splice(topCounter, data.length - 1);

  return data;
};
