import React from 'react';
import {
  PaperplaneIcon,
  LampIcon,
  HeartIcon,
  BookIcon,
  CheckLabelIcon,
  CompassIcon,
  HatIcon,
  SmileyIcon,
  StarIcon,
} from '../Icons';

export const getIcon = name => {
  switch (name) {
    case 'paperplane':
      return <PaperplaneIcon />;
      break;
    case 'book':
      return <BookIcon />;
      break;
    case 'lamp':
      return <LampIcon />;
      break;
    case 'heart':
      return <HeartIcon />;
      break;
    case 'checklabel':
      return <CheckLabelIcon />;
      break;
    case 'compass':
      return <CompassIcon />;
      break;
    case 'star':
      return <StarIcon />;
      break;
    case 'smiley':
      return <SmileyIcon />;
      break;
    case 'hat':
      return <HatIcon />;
      break;
    default:
      break;
  }
};
export default getIcon;
