import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { Box, Stack } from '@mui/material';

const Counter = ({
  title,
  from = 0,
  to = 100,
  animate = false,
  duration = 1000,
  durationPerCount,
}) => {
  const [int, setInt] = useState(Number(from));
  const countInterval = durationPerCount ? duration : duration / (to - from);
  const transitionSpeed = (countInterval / 1000) * 0.15;

  const arrOfInt = Array.from(String(int), Number);

  // Start counting interval
  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      if (int < to) {
        setInt(int + 1);
      }
    }, Number(countInterval));

    // our cleanup will run when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [int]);

  return (
    <Stack direction="row">
      {arrOfInt.map((char, index) => {
        return animate ? (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ y: '20%', opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: '-20%', opacity: 0 }}
              transition={{ duration: transitionSpeed }}
              key={arrOfInt[index]}
            >
              {char}
            </motion.div>
          </AnimatePresence>
        ) : (
          <Box key={index}>{char}</Box>
        );
      })}
    </Stack>
  );
};

export default Counter;
