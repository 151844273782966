import {
  karriereNavigator,
  radarChart,
  ratingButtons,
  progressCircle,
  confirmDialog,
} from './utils/react-mui';

karriereNavigator();
radarChart();
ratingButtons();
progressCircle();
confirmDialog();
// Buttons();
