import React, { useState, useEffect, useContext, useCallback } from 'react';
import StepperContext from '../StepperContext';
import { styled } from '@mui/material/styles';
import {
  Container,
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconAnimation from '../Partials/IconAnimation/IconAnimation';
import AnimatedButton from '../Partials/AnimatedButton/AnimatedButton';
import Login from '../Partials/Login/Login';
import { Default } from './StepExplanation.stories';

const CssTextField = styled(TextField)({
  flexGrow: 1,

  '& .MuiInputBase-root': {
    color: 'white',
    height: '100%',
  },
});

const StepExplanation = props => {
  const { title, text, buttonLabel, nextStep = 'overview' } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show !== undefined ? props.show : true);
  }, [props.show]);

  const { setStepIndex, user } = useContext(StepperContext);

  const buttonClickHandler = () => {
    setStepIndex(nextStep);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', position: 'relative' }}>
      <Stack sx={{ height: '100%', py: 4 }}>
        {/* Headline */}
        {(title || Default.args.title) && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={
                show && {
                  opacity: 1,
                  // scale: 1,
                  scale: 1,
                }
              }
              exit={{
                opacity: 0,
                // scale: [1, 1.05, 0.8],
              }}
              transition={{ duration: 0.3, delay: 0 }}
              key={show}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                component="h1"
                textAlign="center"
                gutterBottom
              >
                {title || Default.args.title}
              </Typography>
            </motion.div>
          </AnimatePresence>
        )}

        {/* Text */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.4, delay: 0.05 }}
            key={show}
          >
            <Typography
              variant="h6"
              component="h2"
              fontWeight={200}
              textAlign="center"
              sx={{ mb: 4 }}
              dangerouslySetInnerHTML={{ __html: Default.args.text }}
            />
          </motion.div>
        </AnimatePresence>

        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.75 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.025, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.025, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.25 }}
            key={show}
          >
            <Box sx={{ pt: 2 }}>
              <AnimatedButton
                activeAnimation
                variant="contained"
                children={Default.args.button.label}
                show={show}
                onClick={setStepIndex && buttonClickHandler}
              />
            </Box>
          </motion.div>
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

export default StepExplanation;
