import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import { Container, Box, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconAnimation from '../Partials/IconAnimation/IconAnimation';
import AnimatedButton from '../Partials/AnimatedButton/AnimatedButton';
import Login from '../Partials/Login/Login';
import useMatomo from '../utils/tracking';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const StepInit = props => {
  const { trackEvent } = useMatomo();
  const { title, subline, buttonLabel, redirectIndex, icon } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(props.show !== undefined ? props.show : true);
    }, 500);
  }, [props.show]);

  const { setStepIndex, setUser } = useContext(StepperContext);

  const buttonClickHandler = () => {
    setShow(false);
    setStepIndex && redirectIndex && setStepIndex(redirectIndex);
    trackEvent(null, 'Start', buttonLabel);
  };

  const loginCallback = user => {
    setUser && setUser(user);
    setTimeout(() => {
      setStepIndex('overview');
    }, 2000);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Stack sx={{ height: '100%' }}>
        {icon && (
          <Box
            flexGrow={1}
            sx={{
              textAlign: 'center',
              py: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconAnimation show={show} icon={icon} />
          </Box>
        )}
        {/* Headline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                // scale: 1,
                scale: 1,
              }
            }
            exit={{
              opacity: 0,
              // scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.2 }}
            key={show}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              component="h1"
              textAlign="center"
              sx={{ mb: 2 }}
            >
              {title}
            </Typography>
          </motion.div>
        </AnimatePresence>
        {/* Subline */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.4, delay: 0.3 }}
            key={show}
          >
            <Typography
              variant="h6"
              component="h2"
              fontWeight={200}
              textAlign="center"
              sx={{ mb: 4 }}
            >
              {subline}
            </Typography>
          </motion.div>
        </AnimatePresence>
        {/* Button */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.05, 0.8],
            }}
            transition={{ duration: 0.4, delay: 0.5 }}
            key={show}
          >
            <Stack sx={{ px: 1 }}>
              <AnimatedButton
                activeAnimation
                variant="contained"
                children={buttonLabel || 'Button'}
                show={show}
                onClick={buttonClickHandler}
              />
            </Stack>
          </motion.div>
        </AnimatePresence>

        {/* Footer */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={
              show && {
                opacity: 1,
                scale: [0, 1.05, 0.95, 1],
                transition: { duration: 0.5, delay: 1 },
              }
            }
            exit={{
              opacity: 0,
              scale: [1, 1.05, 0.8],
              transition: { duration: 0.3, delay: 0.4 },
            }}
            transition={{ delay: 1 }}
            key={show}
          >
            <Stack sx={{ py: 4 }}>
              <Login callbackFn={user => loginCallback(user)} />
            </Stack>
          </motion.div>
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

export default StepInit;
