import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import {
  Container,
  Box,
  Grid,
  Stack,
  Button,
  Typography,
  Link,
} from '@mui/material';

import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import InfoButton from '../../../InfoButton/InfoButton';
import Counter from '../Counter/Counter';
import getIcon from '../utils/getIcon';
import QuestionGroupButton from '../Partials/QuestionGroupButton/QuestionGroupButton';
import {
  getProgress,
  isTopicComplete,
  getCompleteTopics,
  arraysEqual,
  allTopicsComplete,
  allTopicsNotComplete, setUserUrlParameter,
} from '../utils/helper';
import { getTopics, deleteUser } from '../utils/api';
import mockDataTopics from '../example.topics.json';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog';

let paperplaneIcon = { ...getIcon('paperplane') };

paperplaneIcon.props = {
  sx: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
};

const StepOverview = ({ topics, unlockTopicsMode }) => {
  const theme = useTheme();
  const desktopMode = false; // useMediaQuery(theme.breakpoints.up('lg'));
  const stepperContext = useContext(StepperContext);

  const {
    debug,
    mockData,
    topicsHistory,
    setTopicsHistory,
    autoRedirect,
    setAutoRedirect,
    topic,
    setTopic,
    questionGroup,
    setQuestionGroup,
    stepIndex,
    setStepIndex,
  } = stepperContext;

  const [topicsComplete, setTopicsComplete] = useState();
  const [topics_, setTopics] = useState(topics || null);
  const [loading, setLoading] = useState();
  const [completeTopicsShown, setCompleteTopicsShown] = useState([]);
  const [show, setShow] = useState(-1);

  const [tabIndex, setTabIndex] = useState(
    topics ? String(Object.entries(topics)[0][1].uid) : null,
  );

  // State for the RestartConfirm Component
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [showResults, setShowResults] = useState(false);
  const colors = theme.palette;

  // Tab bar click handler
  const tabBarClickHandler = key => {
    setTabIndex(key);
  };

  // Card click action handler
  const cardActionHandler = questionGroup => {
    // console.log('desktopMode: ', desktopMode);

    debug && console.log('Selected topic: ', questionGroup);
    // Set active button state
    !desktopMode && setShow(questionGroup.uid);

    if (setQuestionGroup) {
      stepperContext.setQuestionGroup(questionGroup);
      !desktopMode && stepperContext.setStepIndex('questionGroup');
    }
  };

  // Open first incomplete topic/tab with delay
  // const setTabDelayed = topics => {
  //   debug && console.log('setTabDelayed');

  //   // Change tabIndex with delay
  //   // !topicsHistory?.old >> Step overview is shown for first time (after page reload)
  //   // notCompleteTopics[0] >> There is at least 1 incomplete topic
  //   const incompleteTopics = allTopicsNotComplete(topics);

  //   if (!topicsHistory?.old && incompleteTopics[0]) {
  //     setTimeout(() => {
  //       // Show first incomplete topic
  //       setTabIndex(incompleteTopics[0]);
  //     }, 2000);
  //   }
  // };

  const infoButtonHandler = () => {
    setStepIndex && setStepIndex('explanation');
  };

  const initTopicsData = data => {
    if (setTopicsHistory) {
      setTopicsHistory({
        current: data.topics,
        old: topicsHistory?.current && topicsHistory?.current,
      });
    }

    setTopicsComplete(allTopicsComplete(data.topics));
    setTopics(data.topics);
    setTabIndex(topic?.uid || Object.keys(data.topics)[0]);
  };

  const confirmDialogTrueAction = () => {
    deleteUser()
      .then(res => { debug && console.log('Deleted current user'); })
      .catch(e => { debug && console.warn(e.message); });

    setConfirmOpen(false);
    setStepIndex && setStepIndex('start');
    setUserUrlParameter('');
  }

  // Topic complete, go to next step
  useEffect(() => {
    if (topicsHistory?.current) {
      const newTopics = topicsHistory.current;
      const incompleteTopics = allTopicsNotComplete(newTopics);

      if (topicsHistory?.old) {
        const topicsAmount = Object.keys(topicsHistory.old).length;
        const oldTopics = topicsHistory.old;

        const completedTopicsOld = getCompleteTopics(oldTopics);
        const completedTopicsNew = getCompleteTopics(newTopics);
        const topicsAmountComplete = completedTopicsNew?.length;
        const topicsHaveChanged = !arraysEqual(
          completedTopicsOld,
          completedTopicsNew,
        );

        debug &&
          console.log(
            'topicsHistory: \n',
            topicsHistory,
            '\n completedTopicsOld: \n',
            completedTopicsOld,
            '\n completedTopicsNew: \n',
            completedTopicsNew,
            '\n arraysEqual: ',
            arraysEqual(completedTopicsOld, completedTopicsNew),
          );

        if (topicsHaveChanged && incompleteTopics[0]) {
          // After the first topic was just completed (and results screen was shown)
          setTimeout(() => {
            // Show first incomplete topic
            setTabIndex(incompleteTopics[0]);
          }, 2000);
        }

        if (autoRedirect) {
          // completed topics have changed
          if (topicsHaveChanged) {
            let stepKey = null;

            if (
              topicsAmountComplete > 0 &&
              topicsAmountComplete < topicsAmount
            ) {
              // Topic complete
              debug &&
                console.log('First part complete \nContext: ', stepperContext);
              stepKey = 'topicComplete';
            } else if (topicsAmount === topicsAmountComplete) {
              // All Topics complete
              debug &&
                console.log('All topics complete \nContext', stepperContext);
              stepKey = 'allTopicsComplete';
            }

            if (stepKey) {
              setAutoRedirect(false);

              setTimeout(() => {
                stepperContext?.setStepIndex(stepKey);
              }, 3500);
            }
          }
        }
      } else if (incompleteTopics[0]) {
        // After page refresh

        setTimeout(() => {
          // Show first incomplete topic
          setTabIndex(incompleteTopics[0]);
        }, 2000);
      }
    }
  }, [topicsHistory]);

  // Get topics data on mount
  useEffect(() => {
    // Get topics on mount
    if (!mockData) {
      setLoading(true);
      getTopics(debug)
        .then(res => {
          debug && console.log('Query topics: ', res.topics);
          initTopicsData(res);
          setLoading(false);
        })
        .catch(e => {
          debug && console.warn(e.message);
        });
    } else {
      initTopicsData(mockDataTopics);
    }

    // Show results with short delay
    setTimeout(() => {
      setShowResults(true);
    }, 500);
  }, []);

  // Add selected tab key to context
  useEffect(() => {
    setTopic && topics_ && setTopic(topics_[tabIndex]);
  }, [tabIndex]);

  return (
    <Container
      maxWidth={desktopMode ? 'none' : 'sm'}
      sx={{ height: '100%', pb: 8 }}
    >
      {/* <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          sx={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,.25)' }}
        > */}
      <Stack spacing={1} sx={{ p: 2 }}>
        {/* Header */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, x: 20, scale: 1 }}
            animate={
              show === -1 && {
                opacity: 1,
                x: 0,
                scale: 1,
                transition: { delay: 0.2 },
              }
            }
            exit={{
              opacity: 0,
              x: -20,
              scale: 1,
              transition: { duration: 0.2 },
            }}
            key={show}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Hallo!
            </Typography>
          </motion.div>
        </AnimatePresence>

        {/* Begin of Restart without progress */}
        {topics_ && getProgress(topics_) <= 0 && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 20, scale: 1 }}
              animate={() => {
                if (show === -1) {
                  return {
                    opacity: 1,
                    x: 0,
                    scale: 1,
                  };
                }
              }}
              exit={{
                opacity: 0,
                x: -20,
                scale: 1,
                transition: { duration: 0.2, delay: 0.1 },
              }}
              key={show}
            >
              <Stack spacing={0.5} sx={{ mb: 3 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="end"
                  alignItems="flex-end"
                  sx={{ pb: 1 }}
                >
                  <Stack
                    spacing={{ xs: 1, sm: 4 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    {/* Neu Starten Button */}
                    <Link
                      fontWeight="bold"
                      underline="none"
                      component="button"
                      variant="caption"
                      onClick={() => {
                        setConfirmOpen(true);
                      }}
                      sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{ width: 16, height: 16, mr: 1 }}
                        component="span"
                      >
                        {paperplaneIcon}
                      </Box>
                      Neu starten
                    </Link>
                    <ConfirmDialog
                      open={confirmOpen}
                      onConfirm={() => confirmDialogTrueAction()}
                      onCancel={() => setConfirmOpen(false)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </motion.div>
          </AnimatePresence>
        )}
        {/* End of Restart without progress */}

        {/* Progress info */}
        {topics_ && getProgress(topics_) > 0 && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 20, scale: 1 }}
              animate={() => {
                if (show === -1) {
                  return {
                    opacity: 1,
                    x: 0,
                    scale: 1,
                  };
                }
              }}
              exit={{
                opacity: 0,
                x: -20,
                scale: 1,
                transition: { duration: 0.2, delay: 0.1 },
              }}
              key={show}
            >
              <Stack spacing={0.5} sx={{ mb: 3 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="flex-end"
                  sx={{ pb: 1 }}
                >
                  {/* Progress text */}
                  <Typography variant="caption" fontWeight="bold">
                    <Stack direction="row">
                      Du hast&nbsp;
                      {showResults && show && topics_ ? (
                        <Counter
                          from={0}
                          to={getProgress(topics_) || 0}
                          duration={1700}
                        />
                      ) : (
                        0
                      )}
                      % geschafft
                    </Stack>
                  </Typography>

                  <Stack
                    spacing={{ xs: 1, sm: 4 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    {/* {topicsComplete && (
                        )} */}

                    {/* Link to results */}

                    {/* {topicsComplete && ( */}
                    { (getCompleteTopics(topics_).length > 0 || topicsComplete) && (
                      <Link
                        fontWeight="bold"
                        underline="none"
                        component="button"
                        variant="caption"
                        onClick={() => {
                          setStepIndex && setStepIndex( ( topicsComplete ) ? 'resultsFinal' : 'results');
                        }}
                        sx={{
                          display: 'flex',
                          alignItem: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        Ergebnisse
                      </Link>
                    )}

                    {/* Neu starten Button */}
                    <Link
                      fontWeight="bold"
                      underline="none"
                      component="button"
                      variant="caption"
                      onClick={() => {
                        setConfirmOpen(true);
                      }}
                      sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{ width: 16, height: 16, mr: 1 }}
                        component="span"
                      >
                        {paperplaneIcon}
                      </Box>
                      Neu starten
                    </Link>
                    <ConfirmDialog
                      open={confirmOpen}
                      onConfirm={() => confirmDialogTrueAction() }
                      onCancel={() => setConfirmOpen(false)}
                    />
                  </Stack>
                </Stack>

                {/* Progress Bar */}
                <Box
                  sx={{
                    borderRadius: '9999px',
                    width: '100%',
                    height: 10,
                    overflow: 'hidden',
                    backgroundColor: 'grey.200',
                  }}
                >
                  <motion.div
                    initial={{ width: `${0}%` }}
                    animate={{
                      width: `${topics_ ? getProgress(topics_) : 0}%`,
                      transition: { duration: 2 },
                    }}
                    transition={{
                      type: 'spring',
                      // stiffness: 50,
                      // default: { duration: 2 },
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '9999px',
                        backgroundColor: 'primary.main',
                        height: 10,
                        width: '100%',
                      }}
                    />
                  </motion.div>
                </Box>
              </Stack>
            </motion.div>
          </AnimatePresence>
        )}

        {/* Tab */}
        {tabIndex && topics_ && (
          <Box>
            {/* Tab navigation */}
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0, x: 20, scale: 1 }}
                animate={() => {
                  if (show === -1) {
                    return {
                      opacity: 1,
                      x: 0,
                      scale: 1,
                      transition: { delay: 0.7 },
                    };
                  }
                }}
                exit={{
                  opacity: 0,
                  x: -20,
                  scale: 1,
                  transition: { duration: 0.2, delay: 0.1 },
                }}
                key={show}
              >
                <Grid container direction="row" sx={{ mb: 3 }} spacing={2}>
                  {/* Single tab navigation item */}
                  {Object.keys(topics_).map((key, index) => {
                    const topic = topics_[key];
                    // disable nav item if previous topic's questions are not completed yet
                    const isButtonDisabled =
                      unlockTopicsMode &&
                      index > 0 &&
                      !isTopicComplete(topics_, index - 1);
                    // false;

                    return (
                      <Grid item key={index}>
                        <motion.div
                          initial={{ scale: 1, opacity: 0.5 }}
                          animate={
                            topic.uid === tabIndex && {
                              opacity: [0.5, 0.5, 1],
                              scale: [1, 0.95, 1.05, 1],
                              transition: { duration: 0.3 },
                            }
                          }
                          key={tabIndex}
                        >
                          <Button
                            disabled={isButtonDisabled}
                            variant="text"
                            onClick={() => tabBarClickHandler(key)}
                            sx={{
                              color: colors.text.primary,
                              background: 'transparent',
                              p: 0,
                              minHeight: 'unset',
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                              }}
                            >
                              {topic.label}
                            </Typography>
                          </Button>
                        </motion.div>
                      </Grid>
                    );
                  })}
                </Grid>
              </motion.div>
            </AnimatePresence>

            {/* Tab content */}
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0, y: 0, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                key={tabIndex}
              >
                {/* <SurveyQuestions category={survey[tabIndex].questions} /> */}
                <Stack spacing={{ xs: 1, sm: 1.5, md: 2 }}>
                  {Object.keys(topics_[tabIndex].question_groups).map(
                    (key, index) => {
                      const questionGroup =
                        topics_[tabIndex].question_groups[key];

                      const answersTotal = questionGroup.answered || 0;
                      const questionsTotal = questionGroup.question_amount || 0;
                      const topicComplete = answersTotal === questionsTotal;
                      const topicCompleteShown =
                        completeTopicsShown.includes(key);

                      const animateActivation = !topicCompleteShown;
                      const isActive = topicComplete;

                      const animationCompleteDelay =
                        animateActivation &&
                        0.5 +
                          (completeTopicsShown.length > 1 ? index : 0) * 0.2;

                      // if (topicComplete && !topicCompleteShown) {
                      //   setTimeout(() => {
                      //     setCompleteTopicsShown([
                      //       ...completeTopicsShown,
                      //       key,
                      //     ]);
                      //   }, animationCompleteDelay * 1000);
                      // }

                      return (
                        // Tab card
                        <AnimatePresence key={index} exitBeforeEnter>
                          {/* Select state animation */}
                          <motion.div
                            initial={{ opacity: 0, y: 0, scale: 1 }}
                            animate={() => {
                              if (show === -1) {
                                return { opacity: 1, y: 0, scale: 1 };
                              } else if (show === questionGroup.uid) {
                                return {
                                  opacity: 1,
                                  scale: [1, 1.05],
                                  transition: { duration: 0.2 },
                                };
                              } else {
                                return {
                                  opacity: 0,
                                  y: 0,
                                  x: -20,
                                  transition: { delay: index * 0.1 },
                                };
                              }
                            }}
                          >
                            <QuestionGroupButton
                              icon={getIcon(questionGroup.icon)}
                              label={questionGroup.label}
                              countQuestions={questionsTotal}
                              countAnswers={answersTotal}
                              active={isActive}
                              animate={animateActivation}
                              delayActivateAnimation={animationCompleteDelay}
                              onClick={() => cardActionHandler(questionGroup)}
                            />
                          </motion.div>
                        </AnimatePresence>
                      );
                    },
                  )}
                </Stack>
              </motion.div>
            </AnimatePresence>
          </Box>
        )}

        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            zIndex: 99,
            overflow: 'visible',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Container
            // maxWidth="md"
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              textAlign: 'right',
              flexDirection: 'row',
            }}
            disableGutters
          >
            <Box sx={{ py: 2 }}>
              <InfoButton onClick={infoButtonHandler} />
            </Box>
          </Container>
        </Box>
      </Stack>
      {/* </Grid> */}

      {/* This is where the desktop mode (split screen) could be placed */}

      {/* {desktopMode && (
          <Grid item xs={12} md={7} lg={8}>
            Hallo
          </Grid>
        )} */}
      {/* </Grid> */}
    </Container>
  );
};

export default StepOverview;
