import StepQuestions from './StepQuestions';

const defaultExport = {
  title: 'Components/Stepper/Steps/Questions',
  component: StepQuestions,
  // argTypes: {
  // 	...ImageStories.default.argTypes,
  // },
};

const Template = args => <StepQuestions {...args} />;

export const Default = Template.bind({});

// Handle with care!
// Default args are used for live environment as well.
Default.args = {
  callbackFn: data => console.log('callback data: ', data),
  questions: [
    {
      title: 'Auf welche Schule gehst du?',
      slug: 'auf-welche-schule-gehst-du',
      options: [
        {
          name: 'gemeinschaftsschule',
          label: 'Gemeinschaftsschule',
        },
        {
          name: 'werkrealschule',
          label: 'Werkrealschule',
        },
        {
          name: 'realschule',
          label: 'Realschule',
        },
        {
          name: 'gymnasium',
          label: 'Gymnasium',
        },
        {
          name: 'sonstige',
          label: 'Sonstige',
        },
      ],
    },
    {
      title: 'Welchen Abschluss willst du mal machen?',
      slug: 'welchen-abschluss-willst-du-mal-machen',
      options: [
        {
          name: 'hauptschulabschluss',
          label: 'Hauptschulabschluss',
        },
        {
          name: 'mittlere-reife',
          label: 'Mittlere Reife',
        },
        {
          name: 'abi-fachhochschule-etc',
          label: 'Abitur, Fachhochschulreife, etc.',
        },
        {
          name: 'sonstiges',
          label: 'Sonstiges',
        },
        {
          name: 'keine-angabe',
          label: 'keine Angabe',
        },
      ],
    },
    {
      title: 'Welche Ergebnisse willst du?',
      slug: 'welche-ergebnisse-willst-du',
      options: [
        {
          name: 'studienfaecher',
          label: 'Studienfächer',
        },
        {
          name: 'ausbildungsberufe',
          label: 'Ausbildungsberufe',
        },
        {
          name: 'beides',
          label: 'Beides',
        },
      ],
    },
  ],
};

export default defaultExport;
