
const useMatomo = () => {
  const trackEvent = (category, action, name, value) => {
    let valCategory = category || 'Karrierenavigator';
    let valAction = action || 'Click';
    let valName =  name || 'Button';
    let valValue = ( value ) ? parseInt(value) : 1;

    if (window._paq) {
      let eventData = ["trackEvent", valCategory, valAction, valName, valValue];
      window._paq.push(eventData);
      console.log('Matomo: ' + eventData);
    }
  };

  return { trackEvent };
}

export default useMatomo;
