import ReactDOM from 'react-dom';
import Karrierenavigator from 'components/react/karrierenavigator/karrierenavigator';
import RadarChart from 'components/react/RadarChart/RadarChart';
import ProgressCircle from 'components/react/ProgressCircle/ProgressCircle';
import RatingButtons from 'components/react/RatingButtons/RatingButtons';
import { Button } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from 'components/react/karrierenavigator/theme';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ConfirmDialog from '../../components/react/ConfirmDialog/ConfirmDialog';

// Convert attributes into 'readable' object
export const getAttributesAsObject = attributes => {
  let object = {};

  attributes.map(attribute => {
    object[attribute.name] = attribute.nodeValue;
  });

  return object;
};
//-

export const MuiProvider = ({ children }) => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={lightTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <ScopedCssBaseline />
        {children}
      </ThemeProvider>
    </React.StrictMode>
  );
};

export const karriereNavigator = () => {
  const karrierenavigator = document.getElementById('karrierenavigator');

  if (karrierenavigator) {
    const attributes = getAttributesAsObject([...karrierenavigator.attributes]);

    ReactDOM.render(
      <MuiProvider>
        <Karrierenavigator
          stepIndex={attributes['data-stepindex'] || 'start'}
          dialog={attributes['data-dialog'] || true}
          dialogButton={attributes['data-dialogbutton'] || false}
          dialogOpen={attributes['data-dialogopen'] || false}
          // apiUrl={attributes['data-apiurl'] || 'www.c4f.bwstiftung.de'}
          debug={attributes['data-debug'] || 0}
          user={attributes['data-user'] || null}
          mockData={
            karrierenavigator.attributes['data-mockdata'] ? true : false
          }
        />
      </MuiProvider>,
      karrierenavigator,
    );
  }
};

export const buttons = () => {
  const buttonPrimary = document.querySelectorAll('.button');
  console.info(buttonPrimary);

  buttonPrimary?.forEach(el => {
    const attributes = getAttributesAsObject([...el.attributes]);
    console.log(el.outerHTML);

    let newEl = document.createElement('div');

    el.replaceWith(newEl);

    ReactDOM.render(
      <MuiProvider>
        <Button
          // size="medium"
          // variant="outlined"
          // color="primary"
          {...attributes}
        >
          {el.innerText}
        </Button>
      </MuiProvider>,
      newEl,
    );
  });
};

export const radarChart = () => {
  const els = document.querySelectorAll('.radar-chart');

  els?.forEach(el => {
    const attributes = getAttributesAsObject([...el.attributes]);

    ReactDOM.render(
      <MuiProvider>
        <RadarChart
          labels={JSON.parse(attributes.labels)}
          chartData={JSON.parse(attributes.chartdata)}
          show={true}
          tension={Number(attributes.tension)}
        />
      </MuiProvider>,
      el,
    );
  });
};

export const ratingButtons = () => {
  const els = document.querySelectorAll('.rating-buttons');

  els?.forEach(el => {
    const attributes = getAttributesAsObject([...el.attributes]);

    ReactDOM.render(
      <MuiProvider>
        <RatingButtons />
      </MuiProvider>,
      el,
    );
  });
};

export const progressCircle = () => {
  const els = document.querySelectorAll('.progress-circle');

  els?.forEach(el => {
    const attributes = getAttributesAsObject([...el.attributes]);

    ReactDOM.render(
      <MuiProvider>
        <ProgressCircle
          progress={attributes['data-value']}
          show={true}
          animationDelay={500}
        />
      </MuiProvider>,
      el,
    );
  });
};

import React, { useState } from 'react';
export const confirmDialog = () => {
  const els = document.querySelectorAll('.confirm-dialog');

  /**
   * Dummy component for showing the confirmation dialog example
   *
   */
  const ConfirmDialogExample = () => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    return (<>

      <Button onClick={() => {
        setIsConfirmOpen(true);
      }}>Open Dialog</Button>

      <ConfirmDialog
        headline="Do you want it?"
        open={isConfirmOpen}
        onConfirm={() => {
          alert("Confirmed!");
          setIsConfirmOpen(false);
        }}
        onCancel={() => setIsConfirmOpen(false) }
      />

    </>);
  }

  els?.forEach(el => {
    ReactDOM.render(
      <MuiProvider>
        <ConfirmDialogExample />
      </MuiProvider>,
      el,
    );
  });
};
