import StepUserCode from './StepUserCode';
import StepperContext from '../StepperContext';

const defaultExport = {
  title: 'Components/Stepper/Steps/UserCode',
  component: StepUserCode,
  // argTypes: {
  // 	...ImageStories.default.argTypes,
  // },
};

const Template = args => (
  <StepperContext.Provider
    value={{
      user: 'b78379a7-4302-4100-8c3e-d198f40b0782',
    }}
  >
    <StepUserCode {...args} />
  </StepperContext.Provider>
);

export const Default = Template.bind({});

Default.args = {
  title: 'Dein Karriere-Code',
  // subline:
  //   'Dies ist dein persönlicher Zugriff auf Deinen Test. Bitte notiere dir den Code oder setze ein Lesezeichen',
  subline:
    'Das ist dein persönlicher Zugriff auf deinen Test. Mit diesem Code kannst du dir dein Testergebnis jederzeit wieder ansehen. Bitte notiere dir den Code oder kopiere ihn in deine Notizen.',
  buttonLabel: 'Bookmark setzen',
  copiedCodeConfirmation: 'Karriere-Code in die Zwischenablage kopiert',
  show: true,
  delay: 500,
  icon: 'paperplane',
};

export default defaultExport;
