import React from 'react';
import {
  Document,
  Canvas,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Font,
  Link,
} from '@react-pdf/renderer';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { lightTheme } from '../karrierenavigator/theme';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
import RadarChart from '../RadarChart/RadarChart';
import {
  findByKey,
  getTopData,
  getResultIcon,
  resultDescriptions,
} from './PdfDownloader.helper';

// import FontPoppinsLight from './fonts/poppins-v20-latin-300.woff';
// import FontPoppinsBold from './fonts/poppins-v20-latin-600.woff';

// Register font
Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://www.coaching4future.de/dest/fonts/poppins-v20-latin-300.woff',
      fontWeight: 300,
    },
    {
      src: 'https://www.coaching4future.de/dest/fonts/poppins-v20-latin-600.woff',
      fontWeight: 600,
    },
  ],
  // fontStyle: 'normal',
  // fontWeight: 'normal',
});

// Disable hyphenation
Font.registerHyphenationCallback(word => [word]);

// Create styles
const styles = StyleSheet.create({
  page: {
    // backgroundColor: '#d11fb6',
    // color: 'white',
    fontFamily: 'Poppins',
  },
  head: {
    position: 'relative',
    backgroundColor: lightTheme.palette.secondary.main,
    padding: 0,
    marginBottom: 60,
  },
  head_2: {
    position: 'relative',
    backgroundColor: lightTheme.palette.secondary.main,
    padding: 0,
    marginBottom: 30,
  },
  headInner: {
    paddingTop: 20,
    paddingBottom: 30,
    // backgroundColor: lightTheme.palette.secondary.main,
    // position: 'relative',
    // zIndex: 0,
  },
  headInner2: {
    paddingTop: 33,
    paddingBottom: 17,
    // backgroundColor: lightTheme.palette.secondary.main,
    // position: 'relative',
    // zIndex: 0,
  },
  logoImg: {
    position: 'absolute',
    top: '-3cm',
    right: '.25cm',
    width: '6cm',
    // zIndex: 999,
  },
  imgPapierflieger: {
    position: 'absolute',
    top: '-1.25cm',
    right: '4cm',
    width: '9cm',
  },
  imgLupe: {
    position: 'absolute',
    top: '-2cm',
    left: '-3.25cm',
    width: '8cm',
  },
  section: {
    // marginTop: 20,
    marginBottom: 30,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  headline: {
    fontSize: 28,
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  subline: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1,
  },
  text: {
    fontSize: 8,
  },
  text_small: {
    fontSize: 7.5,
  },
  listHeadline: {
    fontSize: '11pt',
    fontWeight: 600,
  },
  code: {
    fontSize: '11pt',
    color: lightTheme.palette.secondary.dark,
  },
  contentSpacing: {
    paddingLeft: 80,
    paddingRight: 40,
  },
  percentage: {
    fontWeight: 600,
  },
  numeration: {
    fontWeight: 600,
  },
  resultIcon: {
    width: '1cm',
  },
});

const List = ({ items, headline, small = null, startIndex = 0 }) => {
  return (
    <View>
      {headline && (
        <Text
          style={{
            ...styles.listHeadline,
            marginBottom: small ? 12 : 16,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {headline}
        </Text>
      )}
      {items &&
        items?.map((item, index) => {
          return (
            <ListItem item={item} key={index} index={index + startIndex} />
          );
        })}
    </View>
  );
};

const ListItem = ({ item, index, small = null }) => {
  console.log(item.percentage, Number(item.percentage.toFixed(0)));
  return (
    <View
      style={{
        marginBottom: small ? 6 : 8,
        flexDirection: 'row',
        justifyContent: 'start',
      }}
    >
      <ListNumeration number={index + 1} small={small} />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          // flexWrap: 'wrap',
          paddingLeft: 5,
        }}
      >
        <Text style={{ ...styles.text }}>
          {item.title}{' '}
          <Text
            style={{
              ...styles.text,
              ...styles.percentage,
            }}
          >
            {Math.floor(item.percentage)}&nbsp;%
          </Text>
        </Text>
      </View>
      <View
        style={{
          height: 1,
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          paddingLeft: 22,
          bottom: -1,
        }}
      >
        <View
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Canvas
            style={{
              backgroundColor: '#eee',
              height: '100%',
              width: `100%`,
              position: 'absolute',
              bottom: '0',
              left: 0,
            }}
          />
          <Canvas
            style={{
              backgroundColor: lightTheme.palette.secondary.main,
              height: '100%',
              width: `${Math.floor(item.percentage)}%`,
              position: 'absolute',
              bottom: '0',
              left: 0,
              opacity: 0.75,
            }}
          />
        </View>
      </View>
    </View>
  );
};

const ListNumeration = ({ number, small }) => {
  return (
    <View
      style={{
        backgroundColor: lightTheme.palette.secondary.main,
        borderRadius: '999px',
        width: 16,
        height: 16,
        marginTop: small ? -1 : -3,
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          ...styles.numeration,
          letterSpacing: small ? -1 : 0,
          fontSize: 8,
          lineHeight: 0,
          marginBottom: -2,
        }}
      >
        {number}
      </Text>
    </View>
  );
};

const MyDoc = ({
  results,
  descriptions,
  diagramImage,
  qrCode,
  uidLink,
  uid,
}) => {
  const topData = getTopData({ results: { ...results } });
  const diagramData = findByKey(results, 'type', 'scale_final');

  return (
    <Document>
      {/* First page */}
      <Page size="A4" style={styles.page}>
        {/* Page Head */}
        <View style={styles.head} fixed>
          <Image
            src={'/dest/images/c4f/karrierenavigator/Papierflieger.png'}
            style={styles.imgPapierflieger}
          />
          <Image
            src={'/dest/images/c4f/karrierenavigator/Logo.png'}
            style={styles.logoImg}
          />

          <View
            style={styles.headInner}
            render={({ subPageNumber, subPageTotalPages }) => (
              <View style={{ width: '100%' }}>
                <Text
                  style={{
                    ...styles.headline,
                    width: '50%', // subPageNumber === 1 ? '50%' : '100%',
                    marginLeft: 40,
                    fontSize: styles.headline.fontSize,
                    // subPageNumber === 1
                    //   ? styles.headline.fontSize
                    //   : styles.subline.fontSize,
                  }}
                >
                  Karrierenavigator – Dein Ergebnis{' '}
                </Text>
                {subPageTotalPages > 1 && (
                  <Text
                    style={{
                      position: 'absolute',
                      marginLeft: 40,
                      bottom: -18,
                      fontSize: 10,
                      opacity: 0.5,
                    }}
                  >
                    Seite {subPageNumber} / {subPageTotalPages}
                  </Text>
                )}
              </View>
            )}
          />
        </View>

        {/* Page Content */}
        <View style={styles.contentSpacing}>
          <View style={styles.section}>
            <Text
              style={{ ...styles.text, fontWeight: 'bold', paddingRight: 30 }}
            >
              Vielen Dank, du hast jetzt viele Fragen zu deinen Interessen und
              Fähigkeiten beantwortet.
            </Text>
            <Text
              style={{ ...styles.text, fontWeight: 'bold', paddingRight: 30 }}
            >
              Aus deinen Antworten ergibt sich dein Profil, das dir zeigt,
              welche Berufsbereiche, Ausbildungsberufe und Studienfächer am
              besten zu dir passen.
            </Text>
          </View>
          <View style={styles.section}>
            <Text
              style={{ ...styles.subline, marginBottom: 25, paddingRight: 30 }}
            >
              TOP 5 der Ausbildungsberufe und Studienfächer, mit denen dein
              Profil am besten matcht:
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  width: '50%',
                  paddingRight: 10,
                }}
              >
                <List
                  items={topData.apprenticeships}
                  headline="Top 5 - Ausbildungsberufe"
                />
              </View>
              <View style={{ width: '50%' }}>
                <List
                  items={topData.courses}
                  headline="Top 5 - Studienfächer"
                />
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.subline, marginBottom: 12 }}>
              Berufsbereiche
            </Text>
            {/* <View style={{ flexDirection: 'row' }}> */}
            <View style={{ width: '100%' }}>
              {/* Canvas */}
              {/* <Image
                  src={() => diagramImage}
                  // width={'400cm'}
                  // height={'400cm'}
                /> */}

              <Text style={{ ...styles.text, paddingRight: 30 }}>
                Die Ergebnisse zeigen dir, welche{' '}
                <Text style={{ fontWeight: 600 }}>Berufsbereiche</Text> am
                besten mit deinen Interessen und Fähigkeiten übereinstimmen. Je
                höher die Prozentzahl, desto höher ist die Übereinstimmung mit
                deinen Interessen und Fähigkeiten. Eine Erläuterung der
                Berufsbereiche findest du auf der nächsten Seite.
              </Text>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginTop: 16,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    paddingRight: 10,
                  }}
                >
                  <List items={diagramData?.scales.slice(0, 6)} />
                </View>
                <View
                  style={{
                    width: '50%',
                  }}
                >
                  <List items={diagramData?.scales.slice(6)} startIndex={6} />
                </View>
              </View>
            </View>
            {/* <View style={{ width: '20%' }}>
                <Text style={styles.text}>
                  Das Diagramm links zeigt dir, welche{' '}
                  <Text style={{ fontWeight: 600 }}>Berufsbereiche</Text> am
                  besten mit deinen Interessen und Fähigkeiten übereinstimmen.
                  Je höher die Prozentzahl, desto höher ist die Übereinstimmung
                  mit deinen Interessen und Fähigkeiten. Eine Erläuterung der
                  Berufsbereiche findest du auf der nächsten Seite.
                </Text>
              </View> */}
            {/* </View> */}
          </View>
          <View style={{ ...styles.section, fontWeight: 600 }} wrap={false}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  width: '75%',
                  paddingRight: 10,
                }}
              >
                <Text style={{ ...styles.text }}>
                  Du möchtest dir dein gesamtes Ergebnis noch mal ansehen?
                </Text>
                <Text style={{ ...styles.text }}>
                  Mithilfe von deinem Karriere-Code kannst du dein Testergebnis
                  jederzeit auf
                </Text>
                <Text style={{ ...styles.text }}>
                  www.coaching4future.de abrufen. Oder scanne einfach den
                  QR-Code.
                </Text>
                <Text style={{ ...styles.text, marginTop: 8 }}>
                  Karriere-Code:{' '}
                  <Link src={uidLink}>
                    <Text style={styles.code}>{uid}</Text>
                  </Link>
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                }}
              >
                <View
                  style={{
                    width: '100%',
                    border: '1px solid #ccc',
                  }}
                >
                  <Link src={uidLink}>
                    <Image src={qrCode} />
                  </Link>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      {/* Next pages */}
      <Page size="A4" style={styles.page}>
        <View style={styles.head_2} fixed>
          <View style={styles.headInner2}>
            <Image
              src={'/dest/images/c4f/karrierenavigator/Lupe.png'}
              style={styles.imgLupe}
            />
            <Text style={{ ...styles.subline, marginLeft: '4cm' }}>
              Die Berufsbereiche kurz erklärt
            </Text>
          </View>
        </View>
        <View
          style={{
            ...styles.contentSpacing,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          {Object.entries(resultDescriptions).map((item, index) => {
            return (
              <View
                key={index}
                style={{
                  width: '50%',
                  flowGrow: 1,
                  padding: 10,
                  // display: 'flex',
                  position: 'relative',
                }}
                wrap={false}
              >
                <Text
                  style={{ ...styles.text, fontWeight: 'bold', float: 'left' }}
                >
                  <View style={{ padding: 10, position: 'relative' }}>
                    <Image
                      src={() => getResultIcon(item[0])}
                      style={{
                        ...styles.resultIcon,
                        height: '1cm',
                        margin: 10,
                        paddingRight: '.5cm',
                      }}
                    />
                  </View>
                  &nbsp;&nbsp;&nbsp;
                  {[item[0]]}
                </Text>
                <Text
                  style={{
                    ...styles.text_small,
                    fontWeight: 'normal',
                    float: 'left',
                  }}
                >
                  {descriptions[item[0]]}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

const PdfDownloader = ({
  results,
  descriptions,
  uid,
  button,
  chart,
  preview,
}) => {
  const fileName = `Karrierenavigator_Ergebnisse_${uid}.pdf`;
  const altButton = <Button variant="contained">Download now!</Button>;
  const qrCodeText = `https://www.coaching4future.de/karrierenavigator?uid=${uid}`;
  const [pdfDoc, setPdfDoc] = React.useState();

  React.useEffect(() => {
    const canvas = document.querySelector('#radarchartCanvas');

    let docProps = {
      results: { ...results },
      descriptions: { ...descriptions },
      diagramImage: null,
      qrCode: null,
      uidLink: qrCodeText,
      uid: uid,
    };

    QRCode.toDataURL(qrCodeText, function (err, url) {
      docProps.qrCode = url;
    });

    canvas &&
      html2canvas(canvas, {
        width: 500,
        // height: 350,
        scale: 2,
        backgroundColor: null,
      }).then(canvas => {
        const imageObject = canvas.toDataURL();
        docProps.diagramImage = imageObject;
        setPdfDoc(<MyDoc {...docProps} />);
      });

    setPdfDoc(<MyDoc {...docProps} />);
  }, []);

  return (
    <>
      {/* PDF viewer */}
      {pdfDoc && preview && (
        <PDFViewer style={styles.viewer}>{pdfDoc}</PDFViewer>
      )}

      {/* Download button for PDF */}
      {pdfDoc && (
        <PDFDownloadLink
          document={pdfDoc}
          fileName={fileName}
          style={{ textDecoration: 'none' }}
        >
          {({ blob, url, loading, error }) =>
            !loading && !error && (button || altButton)
          }
        </PDFDownloadLink>
      )}

      <Box sx={{ height: 0, overflow: 'hidden' }}>
        <div id="radarchartCanvas">
          <Box sx={{ width: 500 }}>
            <RadarChart
              activeIndex={0}
              show={true}
              animations={Boolean(false)}
              chartData={[...chart.data]}
              labels={[
                ...chart.labels.map((label, index) => {
                  return `${label} (${chart.data[index]}%)`;
                }),
              ]}
            />
          </Box>
        </div>
      </Box>
    </>
  );
};

export default PdfDownloader;
