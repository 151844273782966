import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Container,
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  findFirstFreeAnswer,
  getQuestionPagination,
  allTopicsComplete,
} from '../utils/helper.js';
import AnswerButtons from '../Partials/AnswerButtons/AnswerButtons';
import { getQuestionGroup, postQuestionGroup } from '../utils/api';
import getIcon from '../utils/getIcon';
import mockData from '../example.questions.json';

const StepSurvey = (props = { questionGroup }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const stepperContext = useContext(StepperContext);
  const debug = stepperContext.debug;
  const useMockData = stepperContext.useMockData;
  const setAutoRedirect = stepperContext.setAutoRedirect;

  // From Stepper Context
  // Declare required variables used from Stepper Context
  const { setStepIndex, topicsHistory } = stepperContext;
  const topicsComplete =
    topicsHistory && allTopicsComplete(topicsHistory.current);

  const [showLabels, setShowLabels] = useState();
  const [loading, setLoading] = useState(true);
  // Set current Question as State parameter
  const [questionGroup, updateQuestionGroup] = useState(
    // questionContext || props.questionGroup,
    props.questionGroup,
  );

  const [question, setQuestion] = useState();
  const [pagination, setPagination] = useState(
    questionGroup &&
      question &&
      getQuestionPagination(questionGroup.questions, question.uid),
  );

  useEffect(() => {
    if (stepperContext.questionGroup && !useMockData) {
      getQuestionGroup(stepperContext.questionGroup.uid)
        .then(res => {
          const questionGroup = res.questions.question_group;
          const questionKey = Object.entries(questionGroup.questions)[0][1].uid;
          const question = questionGroup.questions[String(questionKey)];

          if (questionGroup) {
            debug && console.log('mockData questionGroup: ', questionGroup);

            updateQuestionGroup(questionGroup);
            setQuestion(question);
            setLoading(false);
          } else {
            debug && console.log('Error @ getQuestionGroup()');
          }
        })
        .catch(e => {
          console.log(e.message);
        });
    } else {
      const questionGroup = mockData.question_group;

      debug && console.log(Object.entries(questionGroup.questions)[0][1]);
      debug && console.log('mockData questionGroup: ', questionGroup);

      updateQuestionGroup(questionGroup);
      setQuestion(Object.entries(questionGroup.questions)[0][1]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (question) {
      const pagination = getQuestionPagination(
        questionGroup.questions,
        question.uid,
      );

      setPagination(pagination);
      debug && console.log('Pagination: ', pagination);
    }
  }, [question]);

  const handleSelect = (e, key) => {
    debug && console.log('handleSelect');

    const tempQuestionGroup = { ...questionGroup };

    tempQuestionGroup.questions[question.uid].current_value = key;

    updateQuestionGroup({ ...tempQuestionGroup });

    const questionGroupApiBody = {
      question_group_id: questionGroup.uid,
      answers: [
        {
          question_id: question.uid,
          value: Number(key),
        },
      ],
    };

    postQuestionGroup(questionGroupApiBody).then(res => {
      debug && console.log('postQuestionGroup(', questionGroupApiBody, ')');
      debug && console.log('postQuestionGroup response: ', res);
    });

    const nextFreeQuestionId = findFirstFreeAnswer(tempQuestionGroup.questions);
    const nextQuestionId = pagination.nextAnswer;

    debug && console.log('next free question ID: ', nextFreeQuestionId);

    setTimeout(() => {
      if (topicsComplete) {
        // All questions are complete
        if (nextQuestionId) {
          setQuestion(questionGroup.questions[nextQuestionId]);
        }
      } else if (!topicsComplete) {
        if (nextFreeQuestionId) {
          setQuestion(
            questionGroup.questions[nextQuestionId || nextFreeQuestionId],
          );
        } else {
          // Activate autoRedirect to automatically show completion steps after overview page
          setAutoRedirect(true);
          // Go to next step
          setStepIndex('questionGroupComplete');
        }
      }
    }, 1000);
  };

  // let icon = null; // questionGroup.icon && { ...getIcon(questionGroup.icon) };
  let icon = questionGroup?.icon && { ...getIcon(questionGroup.icon) };

  // Modify icon props
  if (icon) {
    icon.props = {
      ...icon.props,
      sx: {
        width: 80,
        height: 80,
        position: 'relative',
      },
    };
  }

  useEffect(() => {
    debug && console.log('questionGroup: ', questionGroup);
  }, []);

  if (loading) {
    return '';
  }

  return (
    !loading && (
      <Container maxWidth="md">
        <Stack sx={{ px: 2, pb: 4 }}>
          {/* <AnimatePresence exitBeforeEnter>
				<motion.div
					initial={{ opacity: 0, x: 20, scale: 1 }}
					animate={{ opacity: 1, x: 0, scale: 1 }}
					exit={{ opacity: 0, x: -20, scale: 1 }}
					// transition={{ delay: 1 }}
				> */}

          {icon && (
            <Box
              sx={{
                mx: 'auto',
                mb: { xs: 0, sm: 0.5, md: 1 },
                '& svg': {
                  width: { xs: 40, sm: 50, md: 60 },
                  height: { xs: 40, sm: 50, md: 60 },
                },
              }}
            >
              {icon}
            </Box>
          )}

          {/* Question group title */}
          <Typography
            variant={matches ? 'h5' : 'h4'}
            textAlign="center"
            fontWeight="bold"
            gutterBottom
          >
            {questionGroup.label}
          </Typography>

          {/* Question title */}
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 20, scale: 1 }}
              animate={{
                opacity: 1,
                x: 0,
                scale: 1,
              }}
              exit={
                pagination?.nextAnswer && {
                  opacity: 0,
                  x: [0, 5, -20],
                  scale: 1,
                }
              }
              transition={{ duration: 0.2 }}
              key={question.uid}
            >
              <Typography
                variant={matches ? 'h5' : 'h4'}
                component="h3"
                textAlign="center"
                gutterBottom
              >
                {question.label}
              </Typography>
            </motion.div>
          </AnimatePresence>

          {/* Pagination */}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mb: { xs: 0.5, md: 2 } }}
          >
            {/* Back button */}
            <IconButton
              size="large"
              disabled={!pagination?.previousAnswer}
              aria-label="Vorherige Antwort"
              onClick={() =>
                pagination?.previousAnswer &&
                setQuestion(questionGroup.questions[pagination.previousAnswer])
              }
            >
              <ChevronLeftIcon />
            </IconButton>

            {/* Answer counter */}
            <Stack direction="row" justifyContent="center" alignItems="center">
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  initial={{ y: 5, opacity: 0, scale: 1 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={
                    pagination?.nextAnswer && { y: -5, opacity: 0, scale: 1 }
                  }
                  transition={{ duration: 0.2 }}
                  key={question.uid}
                >
                  <Typography variant="h5" textAlign="center" fontWeight="bold">
                    {String(pagination?.index + 1)}
                  </Typography>
                </motion.div>
              </AnimatePresence>
              <Typography variant="h5" textAlign="center" fontWeight="bold">
                &nbsp;/ {String(pagination?.total)}
              </Typography>
            </Stack>

            {/* Next button */}
            <IconButton
              size="large"
              disabled={
                !pagination?.nextAnswer ||
                (!questionGroup.questions[pagination.nextAnswer]
                  .current_value &&
                  !question.current_value)
              }
              aria-label="Nächste Antwort"
              onClick={() =>
                pagination?.nextAnswer &&
                setQuestion(questionGroup.questions[pagination.nextAnswer])
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>

          {/* Answer buttons */}
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, transform: 'scaleX(.95)' }}
              animate={{ opacity: 1, transform: 'scaleX(1)' }}
              exit={{
                opacity: 0,
                // scale: 1,
                // x: [0, 5, -20],
                transform: 'scaleX(.95)',
                // transition: { delay: 2 },
              }}
              transition={{ duration: 0.3 }}
              key={question.uid}
            >
              <AnswerButtons
                showLabels={showLabels}
                question={question}
                // activeIndexes={[questionGroup.questions[questionKey].current_value]}
                onClick={key => handleSelect(null, key)}
                sortReverse={true}
              />
            </motion.div>
          </AnimatePresence>
          {/* </motion.div>
			</AnimatePresence> */}
        </Stack>
        <Grid container sx={{ px: 2, pb: 2 }}>
          <Grid item flexGrow={1}></Grid>
          <Grid item>
            <IconButton
              sx={{
                backgroundColor: 'secondary.main',
                borderRadius: '9999px',
                textTransform: 'none',
                p: 0,
                width: 60,
                height: 60,
                '&:hover': {
                  backgroundColor: 'secondary.dark',
                },
              }}
              onClick={() => setShowLabels(!showLabels)}
            >
              <Typography component="span" variant="h5" fontWeight={800}>
                i
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default StepSurvey;
